.Home-second {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(90deg, #57bed7 41%, #cb8185 100%);
  height: 45vh;
}
.Home-center {
  color: white;
}
.institute_home_button {
  background-color: red;
  border-radius: 15px;
  width: 200px;
}
.home-img {
  width: 100%;
}
.home-card {
  //   border-right: 0.5px solid black;
  display: flex;
  justify-content: center;
}
.content-home {
  line-height: 25px;
}
.Home-Icon {
  color: #4f7eb5;
  font-weight: bold;
  margin-right: 3%;
}
.Home-foot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(90deg, #9079b4 41%, #c4cce3 100%);
  height: 25vh;
  margin-left: -4%;
}
.btn-foot {
  border-radius: 15px;
  background-color: red;
}
