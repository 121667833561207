.heading_class {
  font-size: 23px;
  font-weight: 500;
}
.sub_heading_class {
  font-size: 19px;
}
.job_description_title > .description_content {
  margin-left: 20px;
  margin-top: 15px;
}
.job_title_heading {
  font-size: 40px;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.description_button {
  background-color: #ff0000;
  box-shadow: 5px 10px 8px #cf9898;
  border: none;
  border-radius: 6px;
  color: white;
  padding: 7px 15px;
  font-size: 17px;
  font-weight: 600;
  transition: 0.5s ease-in-out;
}
.description_button:hover {
  background-color: black;
  box-shadow: 5px 10px 18px #888888;
}
.description_button1 {
  background-color: #ff0000;
  box-shadow: 5px 10px 8px #cf9898;
  border: none;
  border-radius: 6px;
  color: white;
  padding: 7px 21px;
  font-size: 17px;
  font-weight: 600;
  transition: 0.5s ease-in-out;
}
.description_button1:hover {
  background-color: black;
  box-shadow: 5px 10px 18px #888888;
}
.description_button_container {
  // margin-left: 80%;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 5%;
}
@media screen and (max-width: 497px) {
  .job_description_title > .description_content {
    margin-top: 55px;
  }
}
