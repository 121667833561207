/* .stats-counter h2 {
    font-size: 40px;
    color: #007bff;
    font-weight: bold;
  }
  
  .stats-counter h4 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
  }
  
  .stats-counter p {
    color: #6c757d;
    font-size: 16px;
    margin-top: 10px;
  }
  .stats-counter{
    padding:80px 0px !important;
  } */



.stats-counter {
  padding: 50px 0;
  border-radius: 15px;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.stats-counter h2 {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(90deg, #FF6A3D, #FF8F70);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 8px rgba(255, 122, 89, 0.4);
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

.stats-counter h2:hover {
  transform: scale(1.1);
}

.stats-counter h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0066cc;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-shadow: 0px 0px 10px rgba(0, 102, 204, 0.2);
}

.stats-counter p {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
}

.stats-counter .text-center {
  padding: 20px;
  border-radius: 12px;
  transition: transform 0.3s, background-color 0.3s;
}

.stats-counter .text-center:hover {
  transform: translateY(-10px);

}

.stats-counter .text-center:hover h2 {
  text-shadow: 0px 0px 15px rgba(255, 122, 89, 0.8);
}

.stats-counter .text-center:hover h4 {
  text-shadow: 0px 0px 15px rgba(0, 102, 204, 0.5);
}

.stats-counter [data-aos="flip-left"],
.stats-counter [data-aos="flip-right"],
.stats-counter [data-aos="flip-up"],
.stats-counter [data-aos="flip-down"] {
  transition: all 0.8s ease-in-out;
}

p.stats-text-dark {
  color: #6c757d !important;
  font-weight: 600;
}
.text-center.col-md-3.col-12.aos-init.aos-animate {
  display: flex;
  flex-direction: column;
}