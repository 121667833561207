.AddItem1{
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content:space-evenly;
    align-items: baseline; */
    align-items: baseline;
  
}
.AddItem3{
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline;
    padding-left: 0%; */
    align-items: baseline;
}
.AddItem4{
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline; */
    align-items: baseline;
}
.AddItem2{
   
    padding-left: 17%;
}
.AddItem5{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3%;
}
@media  screen and (max-width:425px) {
    .List{
        text-align: center;
        
    }
    .AddItem2{
        padding-right: 17%;
    }
   .AddItem1{
    width:"32";
   }
}
@media screen and (min-width:320px) and (max-width:425px){
    .AddItem5 .bn{
        width:35%;
    }
    
}
@media screen and (min-width:1024px) and (max-width:2560px) {
    .im{
        width:38vw;
    }
    .Add{
        min-width: 80%;
    }
    .AddLists{
        width: 100%;
        height:auto;
        
    }
    .List{
        font-size: 1vw;
      
        
    }
    .Adds{
        min-width: 32%;
    }
   
}
.AddedList{
    text-align: justify;
    align-items: normal;
    display: flex;
    justify-content: space-evenly;
}
