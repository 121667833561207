// .seminar-cards{
//   width: 350px;
//   // height: 430px;
// }
.clg-semi-btn {
  background-color: #0073e6;
  color: azure;
  border: none;
  border-radius: 10px;
}
.project-heading {
  display: flex;
  justify-content: center;
  align-items: last baseline;
  height: 25vh;
}

.semi-title {
  background-color: #0073e6 !important;
  font-size: 20px;
  // height: fit-content;
}
.semi-title-div {
  display: flex !important;
  flex-direction: column !important;
  // color: rgb(220, 221, 222);
  padding: 10px 10px 0px 0px !important;
}
.seminar-card-title {
  font-size: 30px;
  // height: 10px;
  color: azure;
  text-transform: uppercase;
  font-weight: 700;
}

.seminar-card-footer {
  display: flex;
  justify-content: end;
  align-items: center;
}
// .button-seminar{
//   border:radius: 10px;
//   font-size: 18px;
//   font-weight: 400;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .button-seminar:hover{
//   box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
//   background-color: none;
// }
.filter-section-semi {
  padding: 1rem;
  background-color: rgb(241, 243, 243);
  border-radius: 5px;
  margin-bottom: 1rem;
  border: 1px solid lightslategray;
  margin-left: 5vh;
}

// .semi-card-col {
//   margin-bottom: 1rem;
//   margin-left: 3rem;

// }

.seminar-cards {
  // min-height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 700px;
  // flex: 1 1 calc(50% - 16px);
  // margin-bottom: 16px;
}
.seminar-cards:hover {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
  transform: translateY(-10px);
}

.pages-title-semi {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.seminar-card-flex {
  display: flex;
}

.card-row.row.row-cols-md-4.row-cols-sm-2.row-cols-1.aos-init.aos-animate {
  display: flex;
  justify-content: center;
}

// .semi-card-body.card-body {
//   padding: 30px;
// }
button.button-seminar {
  padding: 10px 4px;
}
.semi-title.card-header {
  padding-left: 54px;
}
select.seminar-select.form-select {
  width: 100%;
  height: 40px;
}
input.seminar-input.form-control {
  height: 40px;
}
// .seminar-card-flex {
//   display: flex;
//   align-items: center;
//   column-gap: 8px;

.card-row.row.row-cols-xl-4.row-cols-md-3.row-cols-sm-2.row-cols-1.aos-init.aos-animate {
  display: flex;
  justify-content: center;
}
.card-row.row.row-cols-xl-4.row-cols-md-3.row-cols-sm-2.row-cols-1 {
  display: flex;
  justify-content: center;
  margin: 0;
}
.internship-title.card-header {
  background-color: #0073e6 !important;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}
.row.internship-flex {
  display: flex;
  justify-content: center;
}
.row.seminar-flex {
  display: flex;
  justify-content: center;
}
button.filter-button.btn.btn-primary {
  margin-bottom: 22px;
}
.card .card-text {
  line-height: 1.5 !important;
  padding: 10px;
  margin-bottom: 0px;
}
.seminar-container {
  background-color: #f9f9f9;
  padding: 20px;
  min-height: 100vh;
  // margin-right: 200px;

  .pages-title {
    font-size: 2rem;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
  }

  .seminar-card {
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }

    .seminar-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 0.8rem;
      padding: 5px 5px;
      border-radius: 5px;
    }
    .seminar-join {
      color: #333;
      margin: 10px 0px 0px 0;
    }

    .seminar-card-title {
      color: #e74c3c;
      font-weight: bold;
      font-size: 1.5rem;
    }
    .seminardaytime {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .seminar-card-date {
      background-color: #3d3d3d;
      color: white;
      font-size: 0.9rem;
      padding: 5px 10px;
      margin-bottom: 0px;
    }
    .seminar-card-daytime {
      background-color: #8b8b8b;
      color: white;
      padding: 5px 10px;
      font-size: 0.9rem;
      margin-bottom: 0px;
    }

    .seminar-card-technology,
    .seminar-card-details {
      color: #555;
      font-size: 0.9rem;
      margin-bottom: 0px;
    }

    .seminar-card-fees {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .seminar-card-link a {
      text-decoration: none;
      color: #e74c3c;
      font-weight: 500;
      margin-bottom: 0px;

      &:hover {
        text-decoration: underline;
      }
    }
    .seminar-button {
      background: 90deg #e74c3c #f57b6d;
      color: white;
    }
  }
}

.semi-card-col {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 4rem;
  // width: 100%;
  margin-top: 4px;
}
.semi-title {
  background-color: #f7f7f7;
  font-weight: bold;
  text-align: left;
}

// .semi-card-body {
//   padding: 1rem;
// }

.seminar-card-flex {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
}

.seminar-icons {
  color: #0073e6;
  margin-bottom: 5px;
}

.button-seminar {
  background-color: #0073e6;
  color: #fff;
  border: none;
  // padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}
button.button-seminar {
  padding: 10px 10px;
}
.button-seminar:hover {
  background-color: #0073e6;
}

.seminar-card-footer {
  text-align: center;
  // padding: 1rem;
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
}
