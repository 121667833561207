.error_msg,
.success_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  margin-left: 20%;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.success_msg {
  background-color: #5cdd5c;
}

.Email_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.green_btn {
  border: none;
  outline: none;
  padding: 10px 0;
  background-color: #3bb19b;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: red;
}
