.skillset_filter {
  .filters {
    padding-top: 2rem;

    .selectMenu {
      width: 100%;
      height: 42px;

      .ant-select-selector {
        height: 42px !important;
      }
    }

    .clearFilter {
      p {
        display: inline-block;
        background-color: var(--light-shade);
        padding: 6px 14px;
        border-radius: 32px;
        cursor: pointer;

        span {
          font-size: 0.8rem;
          font-weight: 600;
        }
      }
    }

    .form {
      &-group {
        margin-bottom: 1rem;

        h6 {
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 6px;
        }
      }
    }
  }

  .filter-container {
    width: 100%;
    padding-inline: 20px;
    background-color: rgba(247, 247, 247, 0.555);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0.15, 0.15) 1.95px 1.95px 2.6px;
    padding-bottom: 20px;
    padding-top: 10px;
    .ant-select-selector {
      padding: 5px;
      border-radius: 5px;
    }

    .p-dropdown .p-dropdown-label {
      margin-top: 0px !important;
    }
  }

  .form-group input {
    border: none !important;
    outline: none !important;
    padding: 0px !important;
    font-size: 15px !important;
  }

  .form-group .ant-select-selection-item {
    font-size: 15px;
    padding: 5px;
  }

  .w-200px {
    width: 200px;
  }
}

.filterpage_inner-wrapper {
  display: flex;

  .col {
    padding-top: 75px;
  }

  .searchwrapper {
    min-width: 400px;
    // height: 50px;
    height: 50px;
  }
  .filterpage--search-form {
    height: 50px;
  }

  .searchicon {
    height: 50px;
  }
}

.clearFilter {
  background-color: rgba(128, 128, 128, 0.404);
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 2px 5px;
  margin-left: auto;
}

.filter-radio-btn .form-check-input::after {
  outline: 1px solid #d9d9d9 !important;
}
.filter-radio-btn .form-check-input::after {
  outline: 1px solid #d9d9d9 !important;
}

.filter-radio-btn .form-check-input[type="radio"]:checked {
  outline: 1px solid #d9d9d9 !important;
}

.no_courses {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
}
