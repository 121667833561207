.tickIcon:hover{
    border: 1px solid white;
}
.data-list-table table > thead > tr > th {
    background-color: white !important; 
    color: black !important;
    border-bottom: 2px solid #4855ff;
    text-align: start !important; 
   
}
.data-list-table tbody > tr > td {
    background-color: #EEEEEE !important;
    border-bottom: 1px solid #CFD2CF;
    font-weight: 300 !important;
    text-align: start !important; 

}
.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #B2B2B2 !important;
}
.data-list-top {
    background-color:  #B2B2B2!important;
    border-radius: 8px !important;
}
.ant-input {
    width: 25% !important;
    height: 30px !important;
}
.ant-table-pagination-right {
    justify-content: center !important;
}
.ant-btn {

    width: auto !important;
}
.data-list-button{
    color: black;
    font-weight: 500 !important;
}
.data-list-button:hover{
    color: #4855ff;
}
.titleActive_Button{
    background-color: rgb(10, 189, 10);
    border: rgb(10, 189, 10);
    border-radius: 5px;
    width: 70px;
    height: auto;
    color: white;
}
// ContactorStaffing
.AddButtonDetails{
    display: flex;
    justify-content: end;
}
.AddCandidates_button{
    background-color: #ff0000;
    border-color: #ff0000;
    color: white;
    font-size: 20px;

    border-radius: 5px;
    height:auto;
    width:auto;
}
// 
.CandidatesDetails_RemoveButton{
    background-color: gray;
    border-color: gray;
    height:auto;
    width:auto;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;


}
.CandidatesDetails_RemoveButton_Container{
    display: flex;
    justify-content: end;
}
h4.data-list-header.pages-title-common {
    padding-top: 0;
    text-align: center;
}