.resume {
    max-width: 900px;
    margin: 0 auto;
    padding: 50px 50px;
    color: #333;
    /* background-color: #f9f9f9; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    animation: fade-in 2s ease-in-out;
    font-family: 'Philosopher', sans-serif!important
  }
  
  @keyframes fade-in {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .resume-header, .resume-section {
    margin-bottom: 30px;
    font-family: 'Philosopher', sans-serif!important
  }
  
  .resume-header h1 {
    font-size: 2.5em;
    color: #007bff;
    text-align: center;
    animation: fade-in 2s ease-in-out;
    font-family: 'Philosopher', sans-serif!important
  }
  
  .resume-section h2 {
    font-size: 1.8em;
    color: #007bff;
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
  }
  
  .resume-section ul {
    list-style: none;
    padding: 0;
  }
  
  .resume-section ul li {
    margin: 8px 0;
    padding-left: 25px;
    position: relative;
  }
  
  .resume-section ul li::before {
    content: "✔";
    color: #007bff;
    font-weight: bold;
    position: absolute;
    left: 0;
  }
  
  .resume-section p, .resume-section ul li {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .contact p {
    font-size: 1.1em;
    display: flex;
    align-items: center;
  }
  
  .contact a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact a:hover {
    text-decoration: underline;
  }

  .share-download {
    margin-top: 20px;
  }
  
  .download-button {
    display: inline-block;
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .share-btn {
    padding: 0%;
    background-color: white;
    color: green;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .whatsapp_btn{
    color: green;
    background-color: white;
  }
  .facebook_btn{
    color: #0056b3;
    background-color: white;
  }
  .mail_btn{
    color: maroon;
    background-color: white;
  }
  .share-btn:hover {
    background-color: #0056b3;
  }
  