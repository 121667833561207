.custom-timepicker {
    width: 200px;
    height: 40px;
    font-size: 16px;
  }
  /* General Form Styling */
.training-form {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .training-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .training-form-item {
    display: flex;
    flex-direction: column;
  }
  
  .training-form-item label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .training-error {
    color: red;
    margin-top: 5px;
    font-size: 0.9em;
  }
  
  .training-time-group {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .training-submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .training-submit-button:hover {
    background-color: #0056b3;
  }
/* Increase input box height and width */
.training-form-item input,
.training-form-item textarea,
.training-form-item .ant-picker,
.training-form-item .ant-input {
  width: 100%; /* Full width of the container */
  height: 50px; /* Adjust as needed for height */
  padding: 10px; /* Add padding for a better look */
  font-size: 16px; /* Adjust font size for readability */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Adjust text area height */
.training-form-item textarea {
  height: 100px; /* Larger height for text areas */
  resize: vertical; /* Allow resizing vertically only */
}

/* Increase dimensions of Ant Design inputs and date pickers */
.training-form-item .ant-picker {
  height: 50px;
  font-size: 16px;
}

.training-form-item .ant-input {
  height: 50px;
  font-size: 16px;
}

/* Adjust TimePicker for better alignment */
.training-form-item .ant-picker-input {
  height: 50px;
  display: flex;
  align-items: center;
}

/* Submit button adjustment */
.training-submit-button {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.training-submit-button:hover {
  background-color: #0056b3;
}
  