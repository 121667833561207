.Dash-row {
  display: flex;
  justify-content: center;
  padding: 5%;
}
.professional-Dash-cards {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%);
  // font-style: italic;
  font-weight: bolder;
  font-size: larger;
  color: gray;
}
.professional-in-card {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.birthday {
  border-color: none !important;
}

/* PERSSONAL INFORMATION  FORM */

.personal-form {
  display: flex;
  flex-direction: column;
}


.pages-title {
  font-size: 25px;
  font-weight: 600;
}

/* MY PROFILE INDEX PAGE */

.professionaltabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

/* QUALIFICAYION DETAILS */

.input-subtitle {
  text-decoration-color: rgb(114, 166, 226);
  font-weight: 700;
  font-size: 16px;
}

/* SEMESTER SCORES PAGE */
.addbutton {
  border-radius: 8px;
  border: none;
  background-color: #1890ff;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.603);
  color: white;
  padding: 5px;
}
.remove-button {
  border-radius: 8px;
  border: none;
  background-color: gray;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.603);
  color: white;
}
.remove-buttontag {
  display: flex;
  justify-content: end;
  margin-right: 50px;
}
.remove-button:hover {
  color: white;
  background-color: #474343;
}
.addbutton:hover {
  color: white;
  background-color: rgb(62, 121, 231);
}
.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}

.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}
.sidenav a,
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.Professional__UpdateProfile__Input {
  width: 90% !important;
}
.input-title {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-bottom: 8px;
  
}

.input-field {
  height: 48px; 
  width: 100%; 
  border-radius: 10px; 
  // border: 1px solid #ccc;
  padding: 8px;
  font-size: 16px;
  border: none;
  outline: none;
}

.domaindropdown {
  height: auto !important; 
}

.input-error {
  color: #e52727;
  font-size: 14px;
}
.custom-button {
  height: 36px !important; 
  width: 100px !important; 
  font-size: 18px; 
  border-radius: 10px !important; 
  padding: 8px 16px; 
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #e52727; 
    color: #fff;
    transform: scale(1.05);
  }
}
