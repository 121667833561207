.my-5.container-fluid {
    padding: 30px;
}
.text-center.shadow-sm.border-0.card {
    padding: 20px 0;
}
.testimonial-container{
  padding: 50px;
  background-color:#f0f4f8 ;
}
.testimonial-card {
    background-color: #f9fafc; 
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-10px); 
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  }
  
  .testimonial-card .card-title {
    color: #333;
    font-weight: 600;
  }
  
  .testimonial-card .card-subtitle {
    color: #7a7a7a;
  }
  
  .container {
    background-color: #f0f4f8; 
    padding: 50px 20px;
  }
  
  h2 {
    color: #495057;
    font-weight: bold;
  }
  
  .slick-dots li button:before {
    color: #495057; 
  }
  
  .slick-dots li.slick-active button:before {
    color: #0d6efd; 
  }
  .testimonial-container-dark{
background-color: #222222;
padding: 50px;
  }

  .college-startnow-bg
  {
  
    
    background: url(https://jano-nextjs.netlify.app/_next/static/media/bg-7.49be5398.png) no-repeat 50%;
    background-size: contain;
    background-repeat: repeat;

 
  }
  .college-startnow-des
  {
    background: linear-gradient(90deg, #161265 0%, #1a64c5 100%) !important;
    padding: 35px 20px 35px 20px !important;
    color: #fff !important;
    box-shadow: 23px 28px 5px rgba(0, 0, 0, 0.9);
    border-radius: 15px;
  }

  
  .college-btn-sttarnow {
    background-color: white !important;
    font-size: 15px;
    color: black !important;
    margin-top: 25px;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid white;
    box-shadow: 0px 0px 10px -4px white;
    font-weight: 500;
    position: relative;
    overflow: hidden;
  
  }
  
  

  .college-btn-sttarnow  .arrow-up,
  .college-btn-sttarnow  .arrow-down
   {
    position: absolute;
    top: 35%;
    right:1px;
    transition: transform 0.8s ease, opacity 0.8s ease;
  }
  
  .college-btn-sttarnow  .arrow-up {
    transform: translateX(0); 
  }
.college-btn-sttarnow .arrow-down {
    transform: translateX(-100%); 
    opacity: 0; 
  }
  
    .college-btn-sttarnow:hover{
    background-color: black !important;
    color: white !important;
    border-color: black !important;
  }
  
 .college-btn-sttarnow :hover .arrow-up{
    transform: translateX(100%); 
    opacity: 0; 
  }
  
  .college-btn-sttarnow:hover .arrow-down{
    transform: translateX(0); 
    opacity: 1; 
  }


  .mode-of-training 
{
  background-image: url(https://www.mazenet.com/corporate-training/img/Corporate-Training.png);
  background-size: cover;
  background-position: center;
  padding: 50px 20px;

  text-align: center;
}


.header-one h2 {
  color: white; /* Light green color */
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.header-one .underline {
  width: 80px;
  height: 3px;
  background: #a0ff8b;
  margin: 0 auto 20px;
}
.training-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.training-box {
  position: relative;
  background-color: #4040408f;
  width: 250px;
  height: 300px;
  margin: 10px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
}

.training-box:hover {
  transform: scale(1.05);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.training-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  opacity: 1;
}

.training-box:hover .training-content {
  opacity: 0; 
}

.training-hover-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white; 
  color: rgb(77, 167, 95); 
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.training-box:hover .training-hover-content {
  opacity: 1; 
}

.training-title {
  color: white;
  font-size: 22px;
  font-weight: 600;
  height: 55px;
  letter-spacing: -1px;
  word-spacing: 2px;
  text-align: center;
}

.icon-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: 10px 0;
}



@media (max-width: 1366px) and (min-width: 1024px) {
  .training-box {
    position: relative;
    background-color: #4040408f;
    width: 200px !important;
    height: 300px;
    margin: 10px;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
  }
  
}

@media (max-width: 1024px) and (min-width: 888px) {
  .training-box {
    position: relative;
    background-color: #4040408f;
    width: 200px !important;
    height: 300px;
    margin: 10px;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
  }
  
}