.row {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.library_firstcontent {
  background-color: gray;
  // display: flex;
  justify-content: center;
  height: 100vh;
}

.library_secondtext {
  margin-top: 8%;
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
}

.nool_image {
  margin-top: 10%;
  // display: flex;
  // justify-content: center;
}

.order_book {
  border-radius: 7%;
  transition: transform 0.2s;
  margin: 0 auto;
  cursor: pointer;
}

.order_book:hover {
  transform: scalex(1.5);
}

.mozhi {
  margin-top: 20%;
  width: 10rem;
  height: 2.3rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: gray;
  border: none;
}

.mozhi .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  top: 15%;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -60px;
}

.mozhi:hover .tooltiptext {
  visibility: visible;
}

.mozhi {
  border: none;
  transition: transform 0.2s;
  cursor: pointer;
}

.mozhi:hover {
  transform: scale(1.2);
}

.book {
  top: 15%;
}

.book {
  border-radius: 7%;
  border: none;
  transition: transform 0.2s;
  margin: 0 auto;
  cursor: pointer;
}

.book:hover {
  transform: scale(1.2);
}

.video-section_card {
  height: 200px;
  width: 200px;
}

.bg-color {
  background-color: #808080;
  overflow: hidden;
  height: 100%;
  padding: 30px;
}

.ui.search {
  margin-top: 50px;
  text-align: center;
}

i {
  cursor: pointer;
}
// .upload-video-btn {
//   height: 50px;
//   border-radius: 5px;
//   background-color: #ff0000;
//   color: aliceblue;
//   border: none;
// }

.prompt {
  height: 50px;
  flex: 2;
  min-width: 500px;
}

.featured-video {
  display: flex;
  cursor: pointer;
  background-color: white;
}

.featured-video .video-info,
.featured-video .video-thumbnail {
  margin-right: 1.5rem;
}

.featured-video .video-title {
  font-size: 1.8rem;
  font-weight: 400;
  padding-top: 20px;
}

/* Video playlists */

.playlist {
  position: relative;
  padding-bottom: 1rem;
  overflow-x: auto;
  margin-top: 2rem;
}

.playlist-title {
  font-size: 1.6rem;
  font-weight: 500;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  border-top: 0.1rem solid #eee;
  padding: 2rem 0;
  color: var(--black-text);
}

.playlist-videos {
  display: flex;
}

.playlist-video {
  flex-basis: 21rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.playlist-video .video-title {
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.5rem 0;
}

.playlist-video .video-creator {
  display: block;
}

.c {
  min-height: 70% !important;
  flex: 1;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: 300px 2fr;
  grid-template-areas:
    "aside header "
    "aside content "
    "left content ";
  grid-gap: 30px;
}

.header {
  grid-area: header;
  height: 30px; /* in real case it's responsive height */
}

.video-thumbnail img {
  height: 100%;
}

.carousel .item {
  min-height: 500px;
}

.item img {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 500px;
  width: 100%;
  background-color: #999999;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
}

.carousel-control-next-icon:after,
.carousel-control-prev-icon:after {
  all: unset;
}

.filter_search_container {
  justify-content: space-evenly;
  // width: 70%;
  margin-inline: auto;
}

.container {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 253, 253);
}
.items {
  background-color: rgba(255, 255, 255, 0.841);
  border-radius: 15px;
  width: fit-content;
  height: 340px;
  object-fit: cover;
  display: inline-block;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-right: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.items:hover {
  transform: scale(1.2);
  margin-top: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  // transition: 0.3s ease-in-out;
  position: relative;
  top: 40px;
  right: 0px;
  // left: 10px;
  background-color: white;
  z-index: 2;
}
.thumbnail-vid {
  width: 400px;
  height: 230px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.channel-icon {
  width: 35px;
  border-radius: 100%;
  position: relative;
  // left: -175px;
  right: 5px;
  top: -75px;
  bottom: 0px;
  margin-left: 10px;
}
.vid-title {
  font-size: 14px;
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 600;
  position: relative;
  // left: 20px;
  margin-left: 50px;
  top: -4px;
  height: auto;
  text-justify: distribute-all-lines;
}
.vid-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 340px;
  text-align: left;
}
.ch-name {
  font-size: small;
  font-weight: 350;
  /* font-family: Arial, Helvetica, sans-serif; */
  position: relative;
  // left: 50px;
  margin-left: 50px;
  bottom: -5px;
  text-align: left;
  margin-bottom: 0px;
}
.duration {
  background-color: rgb(20, 20, 20);
  color: white;
  font-size: smaller;
  width: fit-content;
  border-radius: 4px;
  padding: 2px;
  position: relative;
  // left: 175px;
  margin-left: 20px;
  top: -30px;
}

.video-player_wrapper {
  padding-top: 75px;
  // width: 100%;
}

.player-wrapper {
  width: auto !important; // Reset width
  height: auto !important; // Reset height
}
.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}

.react-player > div {
  position: absolute; // Scaling will occur since parent is relative now
}

.player-wrapper {
  position: relative !important;
  // padding-top: 70.25% !important;
  aspect-ratio: 100 / (1280 / 720);
  height: 700px;
}

.player {
  position: absolute !important;
  top: 150px !important;
  left: 0 !important;
  width: 100% !important;
  height: 80% !important;
}

.full-playersection--wrapper {
  padding: 30px;
}
.video-wrapper h1 {
  padding-top: 90px;
  margin-left: 50px;
}

.vp-center {
  margin-left: 30px !important;
  display: block !important;
}

// // /////////////////////////////////////////////////////////////////////////////@at-root

// /*
//   ==========================
//   Material Icons
//   ==========================
// */

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

// /*
//   ==========================
//   Custom Styles
//   ==========================
// */

.bg-style {
  background: #fff;
  border: 1px solid #e2e2e2;
}
#nav-drawer {
  border-top: 0;
}

* {
  box-sizing: border-box;
  /* border: 1px solid red !important; */
}

// /*
//   ==========================
//   Overall Layout
//   ==========================
// */

.app-wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.scroll {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  justify-content: space-between;
  height: 100vh;
}
.content,
footer {
  display: flex;
  flex-direction: column;
}
#nav-drawer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 240px;
  position: fixed;
  left: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 0.15s;
}
#nav-drawer > * {
  flex: none;
}
.masthead {
  z-index: 1;
  flex: none;
}
.hide {
  transform: translate3d(-100%, 0, 0) !important;
}

// /*
//   ==========================
//   Flex Elements
//   ==========================
// */

.logo-container > *,
.user-container > *,
#nav-drawer > a,
.media,
.lead-btn-row,
.lead-social-btn,
.lead-voting-btn,
.comment-btn-bar,
.button-footer,
.primary-links-footer,
.secondary-links-footer {
  display: flex;
}
.masthead,
.content,
.logo-container,
.user-container,
#nav-drawer > a,
.comment-btn-bar > a,
.lead-social-btn > a,
.lead-voting-btn > a,
.next-up-bar {
  display: flex;
  align-items: center;
}
.masthead,
.lead-btn-row {
  justify-content: space-between;
}
.aside-span {
  display: block;
}
.lead-view-count {
  align-self: flex-end;
}
.media {
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
// button,
// li {
//   flex: none;
// }
// button {
//   display: flex;
//   align-items: center;
// }
.next-up-bar > :first-child {
  flex: 1;
}
.search {
  display: flex;
  flex: 1;
  margin-left: 0.5rem;
}
.search-input {
  flex: 1;
  max-width: 550px;
}
.search-input {
  border-radius: 2px 0 0 2px;
  border: 1px solid #e2e2e2;
}
.search-button {
  border-radius: 0 2px 2px 0;
  border-left: 0;
  margin: 0;
}

// /*
//   ==========================
//   Spacing & Margin
//   ==========================
// */

.main,
.aside {
  width: 400px;
}
.footer {
  min-width: 1000px;
}
.comments,
.btn-upload,
.btn-notifications {
  display: none;
}
.main > *,
aside {
  margin-top: 8px;
  margin-bottom: 8px;
}
.media-figure {
  margin-right: 1em;
}
.playersection-a,
.playersection-btn {
  margin: 0 0.25rem;
}
// ul,
// li {
//   margin: 0.25rem;
// }
.secondary-links-footer {
  padding-bottom: 30px;
}
.primary-links-footer {
  border-top: 1px solid #e2e2e2;
  padding-top: 0.5rem;
}
.up-next {
  border-bottom: 1px solid #e2e2e2;
}
.button-footer,
.primary-links-footer,
.secondary-links-footer {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 0;
  padding-right: 0;
}
.button-footer {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.bg-style {
  padding: 0.5rem;
}
.aside > div {
  padding: 0.5rem 0;
}
#nav-drawer > * {
  padding: 0.5rem;
}
.comment-filter {
  margin: 0.75rem 0 0.75rem 0;
}
.comment-filter > i {
  padding-right: 0;
}
.media-body > h3 {
  margin-top: 0;
  margin-bottom: 0.4rem;
}
hr {
  border: 1px solid #e2e2e2;
  border-bottom: 0;
  margin-top: 1rem;
}
.next-up-bar > :nth-child(2) {
  margin-right: 0.5rem;
  color: rgba(0, 0, 0, 0.54);
}

/* 
  ==========================
  Typography
  ==========================
*/

// h1,
// h3,
// h5,
// p,
// a,
// span,
// button,
// textarea,
// li {
//   font-family: "Roboto", sans-serif;
// }
// h1,
// h3,
// h5,
// p,
// li {
//   color: #212121;
// }
.playersection-h1 {
  font-size: 1.5rem; /* 24px */
  font-weight: 400;
}
.playersection-h3 {
  font-size: 0.8125rem;
  font-weight: 500;
}
.playersection-h5 {
  font-size: 0.8rem;
  margin: 0.4rem 0;
}
.playersection-p,
.playersection-textarea {
  font-size: 0.75rem; /* 12px */
}

.playersection-textarea {
  border: 1px solid #e2e2e2;
  line-height: 2;
}
.playersection-span {
  color: #767676;
  font-size: 0.6875rem; /* 11px/16=.6875 */
}
.lead-view-count {
  font-size: 1.1875rem;
  font-weight: 400;
}
.username {
  font-weight: 500;
  color: #167ac6;
}
a > .playersection-span {
  font-weight: 500;
}
.terms > li {
  font-size: 0.6875rem;
  color: #767676;
}
.terms > li:hover {
  text-decoration: underline;
  color: #167ac6;
}
// strong {
//   font-weight: 500;
// }
button > .playersection-span {
  font-weight: 500;
  color: #333;
}
.footer-btn {
  font-weight: 400;
}
#nav-drawer > a > .playersection-span {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #222;
}
#nav-drawer > h5 {
  color: #cc181e;
}
// i {
//   padding-right: 0.4rem;
// }

/* 
  ==========================
  Buttons & Links
  ==========================
*/

// a {
//   text-decoration: none;
// }
// li {
//   list-style-type: none;
// }
.playersection-btn {
  border: 1px solid lightgrey;
  background: #f8f8f8;
  border-radius: 2px;
}

.subscribe {
  display: flex;
}
.subscribe-btn {
  background: #e62117;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  border: none;
}
.subscribe-btn > span {
  color: #fff;
  font-weight: 400;
  font-size: 0.75rem;
}
.subscribe-count {
  border-radius: 0 2px 2px 0;
  border-left: 0;
  font-weight: 400;
  font-size: 0.75rem;
  border: 1px solid lightgrey;
  background: #f8f8f8;
  padding: 0.32rem;
}
.btn-flat {
  text-align: center;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e2e2;
}
#nav-drawer > a:hover {
  background: #444;
}
#nav-drawer > a:hover > * {
  color: #fff;
}

/* 
  ==========================
  Switch
  ==========================
*/

.switch-container {
  cursor: pointer;
  position: relative;
}
.switch-bar {
  cursor: grab;
  background: rgb(22, 122, 198);
  width: 31px;
  height: 14px;
  border-radius: 8px;
}
.switch-circle {
  position: absolute;
  top: 1px;
  left: 18px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  z-index: 4;
}

// /*
//   ==========================
//   Images
//   ==========================
// */

iframe {
  width: 400px;
  height: 240px;
}
.img-profile {
  height: 48px;
  width: 48px;
}
.img-aside {
  width: 120px;
  height: 90px;
}
.logos {
  background: no-repeat
    url("http://s.ytimg.com/yts/imgbin/www-hitchhiker-vflVV84g4.webp") -167px -205px;
  height: 30px;
  width: 72px;
}
.btn-profile {
  background: url("https://yt3.ggpht.com/-xKakHjmg9Cg/AAAAAAAAAAI/AAAAAAAAAAA/-7SNTsiGgTE/s88-c-k-no/photo.jpg");
  height: 27px;
  width: 27px;
  border: 0;
  background-size: cover;
  border-radius: 50%;
}

/* 
  ==========================
  Medium Screens
  ==========================
*/

@media all and (min-width: 650px) {
  .content {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .aside {
    width: 350px;
    flex: none;
  }
  .main {
    width: 640px;
    flex: none;
    margin-right: 8px;
  }
  iframe {
    width: 640px;
    height: 350px;
  }
  .btn-upload,
  .btn-notifications,
  .comments {
    display: block;
  }
  .search {
    margin-left: 4rem;
  }
}

/* 
  ==========================
  Large Screens
  ==========================
*/

@media all and (min-width: 1000px) {
  .content {
    justify-content: center;
  }
}

.video-rest_wrapper {
  position: absolute;
  top: 800px;
  margin-inline: auto;
  aspect-ratio: 100 / (1280 / 720);
}

aside {
  position: absolute;
  right: 0;
}

// careerblitz-BOOKS

.row {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

/* .Ebook_all-div{
  background-color: grey;
} */

.E-book_caros {
  width: 100%;
  height: 68vh;
}

.EB-contain {
  background-color: white;
}

.Ebook-card_row {
  display: flex;
  justify-content: center;
}

.E-nonecart {
  border: none;
}

.EB_image:hover {
  transform: scalex(1.3);
}

.Hover_eb-button {
  font-weight: 90%;
  font-size: 18px;
  width: 100%;
  height: 37px;
  text-align: center;
  border: none;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.Hover_eb-button:hover {
  background-color: rgb(248, 9, 9);
  transform: scale(1);
  box-shadow: gray -5px 5px;
}

.Hover_eb-button {
  margin-bottom: 42px;
  width: 123px;
  background-color: rgb(248, 9, 9);
  color: black;
}

.Hover_eb-button {
  background-color: #ff0000;
  color: black;
  border: none;
  border-radius: 6px 20px;
  padding: 8px;
  /* box-shadow:  rgba(20, 20, 20, 0.856) 0px 3px 6px 3px inset; */
}
.Hover_eb-button:hover {
  transform: scale(1.1);
}
