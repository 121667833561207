
$primary-color: #007bff;
$secondary-color: #6c757d;
$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;
$background-color: #f8f9fa;
$card-background: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);
$font-family: 'Arial', sans-serif;


body {
  background-color: $background-color;
  font-family: $font-family;
  margin: 0;
  padding: 0;
}

.about-us-container {
  padding: 60px 20px;
  background: linear-gradient(to bottom right, $background-color, #e9ecef);
  border-radius: 20px;
  box-shadow: 0 4px 30px $shadow-color;
  max-width: 1200px;
  margin: auto;
}


h1 {
  font-size: 3rem;
  font-weight: 700;
  color: $primary-color;
  text-align: center;
  text-shadow: 1px 1px 2px $shadow-color;
  margin-bottom: 20px;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
  color: $secondary-color;
  text-align: center;
  margin-bottom: 15px;
}

.description {
  font-size: 1.2rem;
  color: $secondary-color;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 40px;
}

.card {
  border-radius: 15px;
  background-color: $card-background;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 30px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px $shadow-color;
  }

  .card-title {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .card-text {
    font-size: 1rem;
    line-height: 1.5;
  }
}


.text-primary {
  color: $primary-color !important;
}

.text-success {
  color: $success-color !important;
}

.text-danger {
  color: $danger-color !important;
}

.text-warning {
  color: $warning-color !important;
}


// .btn-primary {
//   background-color: $primary-color;
//   border: none;
//   border-radius: 25px;
//   padding: 12px 25px;
//   font-size: 1.2rem;
//   transition: background-color 0.3s, transform 0.3s;

//   &:hover {
//     background-color: darken($primary-color, 10%);
//     transform: scale(1.05);
//   }
// }


.mb-4 {
  margin-bottom: 30px !important;
}

.mb-5 {
  margin-bottom: 40px !important;
}


@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  .description {
    font-size: 1rem;
  }

  .btn-primary {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }
}
