.client-img {
  width: 100%;
  height: 50vh;
}
.client-head {
  display: flex;
  justify-content: center;
  color: rgb(28, 116, 167);
  /* font-family: 'Courier New', Courier, monospace; */
}
.client-card {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border-radius: 6px 20px !important;
  cursor: pointer;
  transition-duration: 0.4s;
}
.partnerimage {
  padding: 2rem !important;
  height: 170px !important;
}
.client-content-img {
  width: 100%;
  height: 55vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}

/* .client-content {
    color: rgb(15, 16, 16);
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10%;
    margin: auto;
  } */
.alert-second-div1 {
  background-image: linear-gradient(
    90deg,
    #94eced 30%,
    #89b3d2 80%,
    #699f9f 170%
  );
  height: 55vh;
}
.client-discuss {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 5%;
}
.client-btn {
  background-color: #29a3a3;
  border: none;
  color: #000000;
  width: 10%;
  font-weight: bold;
}
.client-btn:hover {
  background-color: #0fc5ea;
  color: #000000;
}
