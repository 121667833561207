.unique-contact-page {
    background-color: #222222a2 !important;
}
.unique-contact-card.card {
    background-color: #434242 !important;
}
.unique-contact-card {
    width: 80%;
    height: 400px; 
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #434242;
    color: white; 
    display: flex; 
    flex-direction: column; 
    justify-content: center;
}

h4.text-center.contact-heading.mb-4 {
    color: white;
}

.unique-contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); 
}
label.contactus-label.form-label {
    color: white;
}
p.card-text-contact.card-text {
    color: white;
}


h2.text-center.get-in-touch.mb-5 {
    color: white;
}

.unique-contact-icon {
    color: #ffd700; 
    margin-bottom: 15px; 
}

.unique-contact-page h2 {
    font-weight: 700;
    color: #333;
}

.unique-contact-page h4 {
    font-weight: 500;
    margin-top: 10px;
    color: #555;
}

.unique-contact-page p {
    color: #777;
    margin-bottom: 0;
}

form .form-control {
    border-radius: 10px;
    border: 1px solid #ccc;
}

form .btn {
    border-radius: 50px;
}

.unique-card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center; 
}
.contactus-container.container {
    background: linear-gradient(to right, #F3EFE0, #434242, #222222, #22A39F);
    border-radius: 20px;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .unique-contact-card {
      height: 450px !important;
      width: 85% !important;
    }
  }
  