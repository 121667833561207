.Align {
  background-color: rgb(228, 211, 211);
  color: black;
  width: 100%;
  text-align: center;
}
.align_text
{
  font-size: 25px;
}
.navebar
{
    text-align: start;
    padding-left: 3%;
    padding-bottom: 2%;
    padding-top: 3%;
    height: 120vh;
    margin-top: 1.5%;
    background-color: rgb(228, 211, 211);
}
.Button1
{
  width: 78%;
  margin-top: 10%;  
}
.paragrapha_align {
  padding-top: 5%;
  text-align: left;
}
.hostel_pic {
  width: 72%;
  height: 35vh;
  transition: 1.5s;
  margin-top: 5%;
  border-top-right-radius: 15%;
  border-bottom-left-radius: 15%;
  border-color: gray;
}
.hostel_pic:hover {
  transform: rotate(1);
  box-shadow: 5px 3px 7px 6px rgb(156, 140, 140);
  border-radius: 5%;
}
.bed {
  transition: 0.5s;
}
.bed:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.cupboard {
  width: 30%;
  height: 38%;
  transition: 0.5s;
}
.cupboard:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.table {
  width: 30%;
  height: 35%;
  transition: 0.5s;
}
.table:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.freewifi {
  transition: 0.5s;
}
.freewifi:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.food {
  transition: 0.5s;
}
.food:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.wash {
  transition: 0.5s;
}
.wash:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.gym {
  transition: 0.5s;
}
.gym:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.clean {
  transition: 0.5s;
}
.clean:hover {
  transform: scale(1.05);
  box-shadow: 10px 7px 17px 8px rgba(112, 108, 108, 0.671);
}
.foot {
  padding-top: 5%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding-bottom: 3%;
}

.row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

@media screen and (max-width: 767px) {
  .paragrapha_align {
    text-align: center;
  }
}
@media screen and (max-width: 2559px) {
  .paragrapha_align {
    margin-left: 14%;
  }
}
@media screen and (max-width: 767px) {
  .paragrapha_align {
    margin-left: 0%;
  }
}
@media screen and (max-width: 767px) {
  .hostel_pic {
    margin-left: 16%;
  }
}
@media (min-width:768px) and (max-width: 1440px) {
  .hostel_pic {
    margin-left: 20%;
    padding-top: 8%;
  }
}
.imagewh {
  width: 100%;
  height: 35vh;
  margin-top: 8%;
}
.map_card {
    width: 22rem;
    height: 35vh;
    border-radius: 0%;
    text-align: left;
    margin-top: 6%;
    cursor: pointer;
    transition: 1s;
  }
  .map_card:hover
  {
      transform: scale(1.05);
      box-shadow: rgb(109, 107, 107) -5px 5px 9px 2px;
      
  }
.bt_content1 {
  width: 102%;
  cursor: pointer;
  transition: 0.5s;
}
.bt_content1:hover
{
    transform: scale(1.05);
    box-shadow: rgb(109, 107, 107) -5px 5px;
}
.bt_content2 {
  width: 60%;
  margin-left: 35%;
  cursor: pointer;
  transition: 0.5s;
}
.bt_content2:hover
{
    transform: scale(1.05);
    box-shadow: rgb(109, 107, 107) -5px 5px;
}
@media screen and (max-width: 767px) {
  .map_card {
    height: 44vh;
    width: 21rem;
  }
}
@media screen and (max-width: 767px) {
  .bt_content1 {
    width: 53%;
  }
}
@media screen and (max-width: 767px) {
  .bt_content2 {
    width: 30%;
    margin-left: 0%;
    margin-top: 5%;
  }
}
@media screen and (max-width: 374px) {
  .imagewh {
    width: 105%;
    margin-top: 2%;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .map_card {
    height: 44vh;
    width: 21rem;
    margin-left: 5%;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .imagewh {
    width: 89.5%;
    height: 35vh;
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .map_card {
    height: 44vh;
    width: 23rem;
    margin-left: 7%;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .imagewh {
    width: 86%;
    margin-left: 8%;
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .imagewh {
    width: 86%;
    margin-left: 7%;
  }
}
@media screen and (max-width:767px){
  .map_card
  {
    margin-top: 0%;
  }
}
@media (min-width:768px) and (max-width:1023px)
{
  .navebar
  {
    width: 20%;
  }
}