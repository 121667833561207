.training-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens */
  gap: 20px;
}

@media (max-width: 1024px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr; /* 1 column for small screens */
  }
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 8px;
  font-size: 16px;
}

.technology-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.add-technology-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.submit-container {
  text-align: center;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}
.remove-technology-button {
  width: 35%;
  padding: 7px;
  border-radius: 6px;
  border: 1px solid gray;
}
.remove-technology-button:hover {
  border: 1px solid rgb(102, 102, 102);
  font-size: 15px;
}
.training-input1 {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}
