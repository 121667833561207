.Freelancing_Mainpage_title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 40px;
}
.Freelancing_Mainpage_title::after {
  content: "";
  height: 4px;
  background-color: #ff1e1e;
  position: absolute;
  cursor: pointer;
  margin-bottom: -29px;
}
.Freelancing_Mainpage_title:hover::after {
  width: 270px;
}
.Card_conttent {
  margin-top: 40px;
  margin-left: 10%;
}
.col1 {
  background-color: white;
  width: 20%;
  //   height: auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  box-shadow: 5px 10px 18px #888888;
  margin-right: 40px;
  border-radius: 7px;
}
.col1 > h6 {
  color: black;
  font-size: 20px;
  font-weight: 100;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col1 > .para1 {
  padding: 5px 25px;
  font-size: 15px;
  letter-spacing: 1px;
}
.card_contentImages {
  width: 70%;
  height: 180px;
  margin: 0 50px;
  margin-top: 10px;
}
// carousel image contents
.freelancerCarouselImg1 {
  height: 70vh;
  width: 80%;
  justify-content: center;
  align-items: center;
}
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
}
.carouselbtn1 {
  background-color: #ff1e1e;
  box-shadow: 5px 10px 8px #cf9898;
  color: white;
  padding: 13px 25px;
  margin-left: 10px;
  margin-right: 15px;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}
.carouselbtn1:hover {
  color: white;
  background-color: black;
  box-shadow: 5px 10px 18px #888888;
}

.carouselbtn2_button {
  background-color: #ff1e1e;
  box-shadow: 5px 10px 8px #cf9898;

  color: white;
  padding: 13px 25px;
  border-radius: 10px;
  font-weight: 700;
  border: none;
  font-size: 14px;
  cursor: pointer;
}
.carouselbtn2_button:hover {
  color: white;
  background-color: black;
  box-shadow: 5px 10px 18px #888888;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .btnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .carouselbtn1 {
    margin-bottom: 5%;
    margin-left: 17px;
  }
  .freelancerCarouselImg1 {
    width: 100%;
    height: 300px;
  }
  .col1 {
    width: 90%;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .col1 {
    width: 40%;
  }
  .carouselbtn2_button {
    font-size: 13px;
    padding: 10px 4px;
  }
  .carouselbtn1 {
    font-size: 14px;
    padding: 10px 8px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .col1 {
    width: 25%;
  }
}

// @media screen and (max-width :1024px){
//     .btnContainer{
//        .btnContainer{
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 margin: 13% 0;

//     }
//     .carouselbtn1{
//         margin-bottom: 20px;
//         padding: 10px 25px;
//         margin-right: 1px;
//         border-radius: 10px;
//         font-size: 18px;
//         font-weight: 700;

//     }
//     .carouselbtn2{
//         padding: 10px 25px;
//         margin-right: 15px;
//         border-radius: 10px;
//         font-size: 18px;
//         font-weight: 700;

//     }

// }
// // @media screen and (max-width:430) {
// //     .ButtonCol{
// //         background-color: #FF1E1E;
// //     }

// // }
// .ButtonCol{
//     background-color: white;
//         }
// @media screen and (max-width:570px) {
//     .ButtonCol{
//         height: 200px;
//             }
//             .btnContainer{
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 margin: 13% 0;

//             }

// }
