.category-title {
  color: rgb(24, 80, 148);
}

.staff-title {
  display: flex;
  justify-content: center;
  font-size: 25px;
  color: rgb(8, 56, 113);
  font-weight: 600;
}

// .input_checkbox{
//     display: flex;
//     align-items: center;
// }
// .startEndDate{
//   .ant-picker{
//     border: none !important;
//   }
// }

.checkbox_field {
  display: flex;
  // align-items: center;
  margin-top: 5%;
}

.price_field {
  display: flex;
}

.checkbox_input {
  margin-top: 30px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  // border: none;
}

.card_label {
  display: flex;
  align-items: center;
}

.card-fields {
  width: 300px;
}

.profile-cards {
  // padding: 3px;
  justify-content: center;
  // text-align: center;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  font-weight: bolder;
  color: gray;
  cursor: pointer;
}

.jobcard_title {
  font-size: 20px;
  color: rgb(8 56 113);
  font-weight: 600;
}

.card_company {
  background-color: rgb(185 180 180 / 78%);
  color: black;
  padding: 1px;
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
}

.card_content {
  transition: height 0.4s ease;
  // padding: 0px 20px;
  overflow: hidden;
}

.dropdown_card {
  display: flex;
  justify-content: flex-start;
}

.card_label_inside {
  display: flex;
  justify-content: flex-start;
}

.card_button {
  background-color: var(--pr-color);
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  width: 100px;
  padding: 3px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 60%);
}

.card_button2 {
  background-color: var(--pr-color);
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
  width: 100px;
  height: 50px;
  padding: 3px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 60%);
}

.filter_input {
  width: 80%;
}
