.faq-container {
  padding: 1.5rem !important;
  width: 100%;
}
.faq-accordion .faq-item {
  background-color: #ffffff;
  border: none;
  margin-bottom: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}
.faq-accordion .faq-item:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}
.faq-question {
  background: linear-gradient(90deg, #00c6ff26, #b7d3f1);
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.faq-question:hover {
  background: linear-gradient(90deg, #6daff5, #4390b9);
  color: #e0f5ff;
}
.faq-answer {
  background: #f9fafc;
  padding: 20px;
  font-size: 16px;
  color: #444;
  line-height: 1.6;
}
.accordion-button::after {
  content: "";
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.3s ease;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}
.accordion-button.collapsed {
  background: linear-gradient(90deg, #00c6ff26, #b7d3f1);
}
/* .FAQ-question-section {
  background: #eef7fc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */
.question-illustration {
  width: 219px;
  height: auto;
}
.FAQ-question-title {
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: #007bff;
}
.FAQ-question-description {
  font-size: 14px;
  color: #555;
}
.FAQ-navigateBTN {
  border: 1px solid #cbe2ff;
  background-color: transparent;
  color: #3889ef;
}
