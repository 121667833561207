.addsector-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.sector-table {
  margin-top: 20px;
  transition: transform 0.2s ease;
}

.sector-table:hover {
  transform: none !important;
}
input.me-2.w-75.sector-input.form-control {
  height: 50px;
  padding-left: 14px !important;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.me-2 {
  margin-right: 0.5rem;
}

.text-center {
  text-align: center;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

@media (max-width: 768px) {
  .sector-table {
    font-size: 0.9rem;
  }

  .w-75 {
    width: 60% !important;
  }

  .col-lg-10 {
    max-width: 100%;
  }
}

td.actions-btn-sector {
  display: flex
;
  justify-content: center;
}