.slider-unique {
  height: 80vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.slider-unique .list-unique .item-unique {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}

.slider-unique .list-unique .item-unique img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-unique .list-unique .item-unique .content-unique {
  position: absolute;
  top: 36%;
  width: 1140px;
  max-width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 30%;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
}

.slider-unique .list-unique .item-unique .content-unique .title-unique,
.slider-unique .list-unique .item-unique .content-unique .type-unique {
  font-size: 5em;
  font-weight: bold;
  line-height: 1.3em;
}

.slider-unique .list-unique .item-unique .type-unique {
  color: #14ff72cb;
}

.slider-unique .list-unique .item-unique .button-unique {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 20px;
}

.slider-unique .list-unique .item-unique .button-unique button {
  border: none;
  background-color: #eee;
  font-family: Poppins;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s;
  letter-spacing: 2px;
}

.slider-unique .list-unique .item-unique .button-unique button:hover {
  letter-spacing: 3px;
}

.slider-unique .list-unique .item-unique .button-unique button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}

.thumbnail-unique {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;
}

.thumbnail-unique .item-unique {
  width: 150px;
  height: 220px;
  flex-shrink: 0;
  position: relative;
}

.thumbnail-unique .item-unique img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
}

.nextPrevArrows-unique {
  position: absolute;
  top: 80%;
  right: 34%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.nextPrevArrows-unique button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #14ff72cb;
  border: none;
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  transition: .5s;
  cursor: pointer;
}

.nextPrevArrows-unique button:hover {
  background-color: #fff;
  color: #000;
}


.slider-unique .list-unique .item-unique:nth-child(1) {
  z-index: 1;
}

.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .title-unique,
.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .type-unique,
.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .description-unique,
.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .buttons-unique {
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent .5s 1s linear 1 forwards;
}

@keyframes showContent {
  to {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .title-unique {
  animation-delay: 0.4s !important;
}

.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .type-unique {
  animation-delay: 0.6s !important;
}

.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .description-unique {
  animation-delay: 0.8s !important;
}

.slider-unique .list-unique .item-unique:nth-child(1) .content-unique .buttons-unique {
  animation-delay: 1s !important;
}


.slider-unique.next .list-unique .item-unique:nth-child(1) img {
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  border-radius: 30px;
  animation: showImage .5s linear 1 forwards;
}

@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.slider-unique.next .thumbnail-unique .item-unique:nth-last-child(1) {
  overflow: hidden;
  animation: showThumbnail .5s linear 1 forwards;
}

.slider-unique.prev .list-unique .item-unique img {
  z-index: 100;
}

@keyframes showThumbnail {
  from {
    width: 0;
    opacity: 0;
  }
}

.slider-unique.next .thumbnail-unique {
  animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext {
  from {
    transform: translateX(150px);
  }
}


.slider-unique.prev .list-unique .item-unique:nth-child(2) {
  z-index: 2;
}

.slider-unique.prev .list-unique .item-unique:nth-child(2) img {
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes outFrame {
  to {
    width: 150px;
    height: 220px;
    bottom: 50px;
    left: 50%;
    border-radius: 20px;
  }
}

.slider-unique.prev .thumbnail-unique .item-unique:nth-child(1) {
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail .5s linear 1 forwards;
}

.slider-unique.next .nextPrevArrows-unique button,
.slider-unique.prev .nextPrevArrows-unique button {
  pointer-events: none;
}

.slider-unique.prev .list-unique .item-unique:nth-child(2) .content-unique .title-unique,
.slider-unique.prev .list-unique .item-unique:nth-child(2) .content-unique .type-unique,
.slider-unique.prev .list-unique .item-unique:nth-child(2) .content-unique .description-unique,
.slider-unique.prev .list-unique .item-unique:nth-child(2) .content-unique .buttons-unique {
  animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
  to {
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}

@media screen and (max-width: 678px) {
  .slider-unique .list-unique .item-unique .content-unique {
    padding-right: 0;
  }
  .slider-unique .list-unique .item-unique .content-unique .title-unique {
    font-size: 50px;
  }
}


.viewplans{
  margin-top: 30px;
  padding: 10px 30px;
  background-color: rgba(240, 255, 255, 0.699);
  border-radius: 6px;
  color: #555;
  border: 1px solid azure
  ;
}
.slider-unique .list-unique .item-unique .content-unique{
  background: transparent;
  backdrop-filter: blur(3px);
  padding-right: 0;
  top: 25%;
  left: 30%;
  box-shadow: none;
  border: none !important;
  width: 800px;
  padding: 30px;
}