

.form-container {
  border: 2px solid #ddd; 
  border-radius: 10px; 
  padding: 20px; 
  background-color: #fff; 
}



.label-for-student{
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.custom-form-control.student-personal-info {
  font-family: 'Roboto', sans-serif; 
  font-size: 14px; 
  padding: 10px; 
  border-radius: 5px; 
  border: 1px solid #ccc; 
}

button, .btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 25px;
}

label {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.form-control.student-personal-info {
  font-family: 'Roboto', sans-serif; 
  font-size: 14px; 
  padding: 12px 10px; 
  border-radius: 5px; 
  border: 1px solid #ccc; 
  height: 45px; 
  line-height: 1.6; 
}

textarea.form-control.student-personal-info {
  height: auto; 
  min-height: 120px;
}
input.student-personal-info.form-control {
  padding-left: 14px !important;
}
input.student-personal-info.form-control {
  margin-left: 0;
}
label.student-info.form-label {
  margin-left: 0;
}
select.student-personal-info.form-select {
  width: 90%;
  height: 45px !important;
  padding-left: 14px !important;
}
textarea.student-personal-info.form-control {
  margin-left: 0;
  padding-left: 14px !important;
  width: 96%;
}
.custom-multi-select {
  height: 150px;
  overflow-y: auto;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}