.Internship {
    background-image: linear-gradient(90deg, #2f57ef7d 0%, #c586ee52 100%),
    linear-gradient(90deg, #2f57efbf 0%, #c586eeab 100%);
    align-content: center;
    height: 20vh;
    position: relative;
}
.Internship h3{
    text-align: center;
    color: white;
}
.internpng {
    position: absolute;
    right: 0;
    top:0;
    height:20vh;
}
.internship-heading {
    text-align: center;
    color: black;
    padding: 20px;
}
.internship-content {
    border: 1px solid #808080;
    border-radius: 5px;
    padding: 10px;
}
.internship-miniheads {
    color: #808080;
    letter-spacing: 2px;
    padding: 5px;
    margin-bottom: 0;
}
.internship-mini {
    color: #808080;
    padding: 5px;
    /* padding: 10px; */

}
.internship-description {
    text-align: justify;
    padding: 10px 30px;
}
.internship-Subhead {
    font-weight: bold;
    letter-spacing: 1px;
}
.intern-skills {
    color: #2c2c2c;
    background-color: #ebebeb;
    padding: 5px 20px;
    border-radius: 20px;
}
