.CoursePackage{
    width: 86%;
    height: 50vh;
    border-radius: 15px;
}
.CoursePackage_ButtonContainer{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.CoursePackage_Button{
    background-color: #ff0000;
    box-shadow: 0 5px 5px 0 grey, 0 3px 5px 0 rgb(0 0 0 / 85%) inset;
    height: auto;
    font-weight: 700;
    font-size: 15px;
    border:1px solid #ff0000;
    margin-bottom: 19px;
}

ul {
	font-size: 16px;
    font-weight: 600;
	line-height: 1.5em;
	margin: 5px 0 15px;
	padding: 0;
}
li {
    list-style: none;
    position: relative;
    /* padding: 0 0 0 20px; */
}
ul.square
	
		li::before {
      content: ""; 

      position: absolute; 
      left: 0; 
      top: 2px; 
      border: solid 8px  #ff0000;
      border-radius: 8px;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px;
		}

        ul.square
        li::after {
      content: ""; 
      position: absolute; 
      left: .25%;
      top: 5px;
      width: 3px;
      height: 6px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .square_li{
        margin-bottom: 5px;

    }
     .coursePackage_heading{
font-size: 10px !important;
     }
	
     @media screen and (min-width:320px) and (max-width:425px){
       .CoursePackage_heading{
            font-size: 17px !important;
        }
        .CoursePackage_del{
            font-size: 15px !important;
        }
        
     }
   
        
     @media screen and (min-width:992px) and (max-width:1355px){
       
         .CoursePackage{
           
            height: auto;
         }
         .CoursePackage_heading{
            font-size: 15px !important;
            font-weight: 500;
        }
        .CoursePackage_del{
            font-size: 14px !important;
        }
        ul {
            font-size: 13px;
            font-weight: 600;
        }
     
        }