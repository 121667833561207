.scroll-container {
    border: 1px solid rgb(65, 65, 65);
    border-radius: 5px;
    height: 50vh;
    overflow: hidden;
  }
  
  .scroll-text {
    height: 100%;
    text-align: center;
    
    /* animation properties */
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    
    -moz-animation: my-animation 5s linear infinite;
    -webkit-animation: my-animation 5s linear infinite;
    animation: my-animation 5s linear infinite;
  }
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateY(-100%); }
    to { -moz-transform: translateY(100%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateY(-100%); }
    to { -webkit-transform: translateY(100%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    to {
      -moz-transform: translateY(100%);
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
  }