.data_list_main {
    margin: 0 5%;
  }
.data_list_header {
    padding-top: 4%;
    margin-left: 0%;
    font-weight: bold; 
  }
  
  .data_list_top {
    height:30px;
    width: 100%;
    // background-image: linear-gradient(#42424a, #4a4545) !important;
    background-color:#4b6cb7 !important ;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-size: 222px;
  }
  .data_list_search{
    width: 25%;
    height: 30px;
    border-radius: 15px;
    border: none;
    outline: none;
  }
  .data_list_button {
    height: 40px;
    border-radius: 5px;
    font-weight: bold;
    border: none;
    outline: none;
  }
  .data_list_table table > thead{
    background-color: #4b6cb7 !important ;
  }
  .data_list_table table > thead > tr > th {
    font-family: "Playfair Display", serif;
    font-size: 18px;
    // background-image: linear-gradient(#42424a, #4a4545) !important;
    // background-color: #fafafa;
  
    color: black;
    text-align: center;
    border-bottom: 2px solid #ff0000;
  }
 
  
  .data_list_table {
    overflow-x: scroll;
    border-radius: 15px;
  }
  
  .data_list_table .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    height: 40px;
    border-radius: 4px 0px 0px 4px;
  }
  
  .data_list_table .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    height: 40px;
    background-color: #4b6cb7 !important;
  }
  .ant-table-thead > tr > th {
    background-color: #4b6cb7 !important;
    color: white !important;
    font-weight: bold;
  }
  .ant-table-thead > th{
    border-radius: 8px !important;
  }
  .ant-table{
    border-radius: 10px !important;
  }
  /* Table cell style */
  .ant-table-tbody > tr > td {
    background-color: #fff;
    color: #333;
    padding: 12px;
    text-align: center;
  }
  
  /* Table row hover style */
  .ant-table-tbody > tr:hover {
    background-color: #e6f7ff !important; /* Light blue background on hover */
  }
 
  /* Table pagination style */
  .ant-pagination {
    margin: 20px 0;
  }

  @media (min-width: 571px) and (max-width: 1024px) {
    .data_list_main {
      margin: 0;
      padding: 15% 4%;
    }
  
    .data_list_search {
      width: 70%;
    }
  
    .data_list_button {
      width: 100px;
    }
  }
  
  @media (min-width: 320px) and (max-width: 570px) {
    .data_list_main {
      margin: 0;
      padding: 15% 3%;
    }
  
    .data_list_search {
      width: 60%;
    }
  
    .data_list_table {
      overflow-x: scroll;
      overflow: hidden;
    }
  
    .data_list_button {
      width: 100px;
    }
  }
  .ant-btn {
    
    height: 25px !important;
    padding: 1px 9px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

/* Custom Modal Title Styling */
.modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
}

/* Custom Close Icon Styling */
.custom-close-icon {
  font-size: 16px;
  color: #999;
  &:hover {
    color: #333;
  }
}

/* Custom Modal Styling */
.custom-modal .ant-modal-content {
  border-radius: 8px;
  padding: 20px;
}

.custom-modal .ant-modal-header {
  border-bottom: none;
}

.custom-modal .ant-modal-footer {
  border-top: none;
  padding-top: 10px;
}

/* Custom Select Styling */
.custom-select .ant-select-selector {
  border-radius: 4px !important;
  padding: 8px;
}

.ant-modal-close-x{
  margin-left: 85px !important;
}