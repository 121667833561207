// /* .content-style{
//     background-color: rgb(228, 226, 223);
//     background-image: linear-gradient(to right, rgb(204, 183, 183) , rgb(149, 149, 134));
// } */

// .Create-input {
//   width: 80% !important;
//   border-radius: 5px;
//   border: 1px solid #208ce538;
//   height: 37px;
//   outline: none;
// }
// .Create-input:hover {
//   border: 1px solid #708fae;
// }

// .create-select {
//   width: 80% !important;
//   border-radius: 5px;
//   height: 37px;
//   width: 80% !important;
// }
// .profile-input {
//   width: 80% !important;
//   border-radius: 5px;
//   border: 1px solid #ced4da;
//   outline: none;
// }
// .profile-input:hover {
//   border: 1px solid #708fae;
// }
// .button-create {
//   display: flex;
//   justify-content: center;
// }
// .create-submit {
//   border-radius: 20px !important;
//   border: 1px solid gray;
//   color: white;
//   width: 100px;
//   height: 5vh;
// }
// .create-submit:hover {
//   box-shadow: 0 5px 8px 0 rgb(72, 72, 74) !important;
// }
// .create-cancel {
//   border-radius: 20px !important;
//   border: 1px solid gray;
//   color: white;
//   width: 100px;
//   height: 5vh;
// }
// .create-cancel:hover {
//   box-shadow: 0 5px 8px 0 rgb(72, 72, 74) !important;
// }
// .text-danger {
//   position: absolute;
//   font-size: 14px;
// }
// .create-cancel:hover {
//   box-shadow: 0 5px 8px 0 rgb(72, 72, 74) !important;
// }
// .text-danger {
//   position: absolute;
//   font-size: 14px;
// }
// .button-input {
//   width: 350px;
// }
// .interview-card {
//   padding: 10px;
//   height: 69vh !important;
// }
// .interviews-card {
//   margin-left: 8%;
//   height: 69vh !important;
// }
// .second-card-details {
//   margin-top: 10%;
// }
// .image-tag {
//   width: 100%;
//   padding: 20px;
// }
// .interivew-head {
//   display: flex;
//   justify-content: space-between;
// }
// .icon-tag {
//   padding: 4px;
//   height: 34px;
//   width: 32px;
//   border-radius: 9px;
//   text-align: center;
//   margin-right: 20px;
//   color: blue;
//   background-color: #8080802b;
// }
// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//   color: black;
//   text-shadow: 0 0 0.25px currentcolor;
//   font-weight: 300;
// }
// .review-card-tag {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 13px;
// }
// .review-image-tag {
//   width: 38%;
//   margin-left: 30px;
// }
// .review-card1-tag {
//   padding: 15px;
// }
// .review-icon-style {
//   display: flex;
//   justify-content: end;
// }
// .review-icon {
//   text-decoration: none;
// }
// .success-message {
//   color: green; /* Change color as needed */
//   font-weight: bold;
// }

/* projectpost.scss */

/* Container Styling */
.content-style {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Page Title */
.pages-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

/* Form Labels */
.create-title,
.mb-1 {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 0.5rem;
  display: block;
}

/* Inputs */
.Create-input,
.box-input-sign,
.input1 {
  // width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 0.75rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.Create-input:focus,
.box-input-sign:focus,
.input1:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}


.p-dropdown {
  width: 100%;

}

.p-dropdown .p-dropdown-label {
  padding: 2px;
  font-size: 1rem;
  }


.error-text-color {
  font-size: 0.875rem;
  color: #ff4d4f;
  margin-top: -0.5rem;
  margin-bottom: 0.75rem;
}


.create-btn-submit {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-btn-submit:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.create-btn-submit:active {
  transform: scale(0.95);
}


.row {
  margin-bottom: 1rem;
}


@media (max-width: 768px) {
  .content-style {
    padding: 1.5rem;
  }

  .pages-title {
    font-size: 1.5rem;
  }

  .create-title,
  .mb-1 {
    font-size: 0.9rem;
  }

  .Create-input,
  .box-input-sign,
  .input1 {
    font-size: 0.9rem;
    padding: 0.4rem 0.6rem;
  }

  .create-btn-submit {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}
