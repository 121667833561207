.row {
    --bs-gutter-x: 0px;
    --bs-gutter-y: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .accessories_caros {
    width: 100%;
    height: 68vh;
  }
  
  .access_products {
    position: relative;
    width: 68%;
  }
  
  .productcontent_access {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    backface-visibility: hidden;
    cursor: pointer;
  }
  
  .accessproduct_tit {
    transition: 0.5s;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .productcontent_access:hover {
    transform: scale(1);
  }
  
  .access_products:hover .productcontent_access {
    color: gray;
    opacity: 0.6;
  }
  .access_products:hover .accessproduct_tit {
    opacity: 1;
  }
  
  .accessproducts_txt {
    color: black;
    font-size: 1.5rem;
    padding: 16px 32px;
  }
  
  .flixier_product {
    margin-top: 3%;
    /* margin-bottom: 3%; */
    display: flex;
    justify-content: space-evenly;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .product {
      width: 90%;
    }
  }
  
  .discounts {
    margin-top: 10px;
    border-radius: 15px;
    cursor: pointer;
  }
  
  .offers {
    margin-top: 6%;
  }

  /* .head {
    margin-top: 50px;
    letter-spacing: 1px;
    color: black;
    text-align: center;
  } */
  
  .collect_img {
    margin-top: 10%;
  }
  
  
  .order_must {
    border-radius: 7%;
    transition: transform .2s;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .order_must:hover {
    transform: scalex(1.5); 
  }