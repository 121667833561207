// .Project-main {
//   padding: 2rem;
// }

// .Project-row {
//   padding: 1rem;
// }

// .Project-col {
//   padding: 1rem;
// }

// .Int-popular-head {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .Int-col {
//   width: 100%;
// }

// .Inttitle {
//   text-align: center;
//   margin-top: 5%;
//   width: 90%;
//   margin-left: auto;
//   margin-right: auto;
//   border-radius: 5px 20px;
//   border: 1px solid #e6e2e2;
//   cursor: pointer;
//   transition-duration: 0.4s;
//   font-size: small;
//   font-weight: 600;
//   color: rgb(85, 81, 81);
// }

// .Inttitle:hover {
//   box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
// }

// .Int-card-image {
//   width: 100%;
//   height: 20vh;
// }

// .Int-card {
//   cursor: pointer;
//   transition-duration: 0.4s;
//   font-weight: 600;
//   color: rgb(85, 81, 81);
//   border: 1px solid #e6e2e2;
//   border-radius: 8px;
// }

// .Int-card:hover {
//   box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
// }

// .Int-card-image2 {
//   width: 100%;
//   height: 35vh;
//   border-top-left-radius: 8px !important;
//   border-top-right-radius: 8px !important;
// }

// .Int-para1 {
//   background-color: #e8e6e6;
//   width: 10rem;
//   padding-left: 2%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .Project-para-content {
//   display: flex;
// }

// .Int-para2 {
//   gap: 0.3rem;
//   display: flex;
//   padding-right: 0.5rem;
//   margin-bottom: 0;
//   align-items: center;
// }

// .Project-body {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
// .filter-request {
//   height: 35%;/* Adjust as needed */
// }

// .cards-container {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 16px; /* Adjust as needed */
//   margin-left: 28px;
// }

// .Int-card {
//   flex: 1 1 calc(50% - 16px); /* Adjust the card width */
//   margin-bottom: 16px; /* Adjust as needed */
// }

// /* Ensure the filter section takes up 1/4th of the width */
// .filter-col {
//   flex: 0 0 25%;
//   max-width: 25%;
// }

// /* Ensure the card section takes up 3/4th of the width */
// .cards-col {
//   flex: 0 0 75%;
//   max-width: 75%;
// }


// @media (min-width: 100px) and (max-width: 500px) {
//   .Project-main {
//     padding: 0;
//   }

//   .Project-row {
//     padding: 0;
//     display: flex;
//     justify-content: center;
//   }

//   .Project-col {
//     padding: 0.2rem;
//     margin-bottom: 1rem;
//   }

//   .Project-para-content {
//     display: block;
//   }

//   .Project-body {
//     display: block;
//   }

//   .Project-btns {
//     margin-top: 1rem;
//     display: flex;
//     justify-content: center;
//   }
// }

// @media (min-width: 500px) and (max-width: 800px) {
//   .Project-row {
//     padding: 0;
//   }

//   .Project-col {
//     padding: 0.2rem;
//     margin-bottom: 1rem;
//   }

//   .Project-body {
//     display: block;
//   }

//   .Project-btns {
//     margin-top: 1rem;
//     display: flex;
//     justify-content: center;
//   }
// }

// .pages-title-approved {
//   color: green;
// }

// .filter-title{
//   font-size: 25px;
//   font-weight: 600;
//   display: flex;
//   justify-content: center;
// }
// .filter-request{
//   height:600px;
//   background-color:  rgb(241, 243, 243); 
// }
// .Int-card {
//   cursor: pointer;
//   transition-duration: 0.4s;
//   font-weight: 600;
//   color: rgb(12, 12, 12);
//   background-color: transparent;
//   border: 1px solid #e6e2e2;
//   border-radius: 8px;
//   width: 200px !important;
 
// }

// .Int-card:hover {
//   box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
//   // background-image: url(https://c1.wallpaperflare.com/preview/289/102/403/various-book-books-college.jpg);
//   // background-color: rgb(232, 233, 233); 
 
//   // font-size: 15px;

// }

// .Int-card:hover .Int-para1 {
//   background-color: rgba(0, 0, 0, 0.19);
// }



// .p-4 {
//   // background-image: url(https://c1.wallpaperflare.com/preview/294/960/948/university-student-graduation-photo-hats.jpg) ;
//   background-color: rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.19);
// }

// .Int-title {
//   color: azure;
//   font-weight: 750;
//   text-transform: uppercase;
// }

// .crd-size {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   border: 1px solid lightslategray;
//  background-color: #0073e6 !important;
 
// }

// .pages-title {
//   display: flex;

// }
// .request-footer{
//  display: flex;
// justify-content: end;
// }

// .view-left {
//   border: none;
//   background-color: #0073e6;
//   color: azure;
//   width: 150px;
//   border-radius: 10px;
//   font-size: 18px;
//   font-weight: 400;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//  font-weight: 400;
// }
// .Int-card:hover .view-left{
//   opacity: 1;
//   transition: 0.5s ease;
// }

// .jobscardds {
//  background-color:white;
//   color: black;
//   cursor: pointer;
//   animation: fadeInUp 0.5s;
//   width: 45vh;
//   height: 40vh;
// }
// .title-internx{
//   text-transform: uppercase;
//   font-weight: 750;
//   color: azure;
// }
// .intern-head{
//   background-color: #0073e6 !important;
// }
// .DescriptionBoxFilter{
//   height:600px;
//   background-color:  rgb(241, 243, 243); 
// }

// .title-intern{
//   text-transform: uppercase;
//   font-weight: 750;
// }
// .intern-view-btn{
//   border: none;
//   background-color: #0073e6;
//   color: azure;
//   width: 100px;
//   border-radius: 10px;
// } 
// .pending-card{
//   width: 350px;
//   height: 200px;
// }
// .pending-stack{
//   background-color:  '#FFEB3B';
//   display: flex;
//   justify-content: end !important;
// }
.Int-card {
  background-color: #f5f5f5; 
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  margin-bottom: 0;
  border-radius: 8px;
  overflow: hidden;
  width: 80%;
  

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
}

.Int-card .card-body {
  padding: 20px;
  text-align: center;
  background-color: #ffffff; 
  color: #333; 
}

.Int-para1 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.Int-para {
  font-size: 1rem;
  color: #555;
  text-align: center !important;
}
.pages-title-approved {
  color: green;
 
 }
 .pages-title {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  
}