.course-card{
    background-color: #f7faff;
}
.course-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  .course-card {
    background-color: #f7faff;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-card:hover {
    background-color: #e0f7fa;
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}
  
  .course-logo {
    max-width: 100%;
    height: auto;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .card-body .row {
    margin-bottom: 1rem;
  }
  
  .mb-3 {
    margin-bottom: 1rem;
  }
  .button-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter-container{
    display: flex;
    justify-content: center;
  }
  
  .me-2 {
    margin-right: 0.5rem;
   
  }
  .tags{
    font-size: 12px;
    margin-bottom: 4px
  }
  h2{
    color: #7485a6;
    
  }
 X

  .row {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .mb-4.col-xl-3.col-lg-4.col-md-6.col-sm-12 {
    padding-right: 10px;
    padding-left: 10px;
}
.get-course{
    font-size: 14px;
}
  
  
 
  @media (min-width: 1200px) {
    .course-card {
      width: 100%;
    }
  }
  
  @media (max-width: 767.98px) {
    .course-card {
      padding: 0.5rem;
    }
  }
  