.loginCard {
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    border-radius: 6px 20px !important;
    cursor: pointer;
    transition-duration: 0.4s;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  }
  .loginCard:hover {
    box-shadow: 0 12px 16px 0 rgba(219, 24, 24, 0.24);
  }