.subscribe-section {
  background-color: #f9f9f9;
  padding: 40px 0;
  width: 100%;
  height: 400px;

 
  display: flex;
  flex-direction: column;

}

.subscribe-form {
  max-width: 600px;
  width: 100%;
}
.subscribe-title{
  color: white;
  padding-bottom: 10px;
  font-weight: bolder;
}
.subscribe{
  background-color: #0056b3!important;
  border-radius: 30px !important;
  flex-direction: column;
}

.input-container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.email-input {
  width: 100%;
 text-indent: 58px;
  border: none;
  border-radius: 5px;
  height: 90px;
}

.email-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px;
  color: #888;
}

.subscribe-button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0 15px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
input.email-input.form-control {
  height: 78px;
}

.subscribe-button svg {
  margin-right: 5px;
}

.subscribe-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .subscribe-button {
  width: 122px !important;
  }
}

@media (max-width: 480px) {
  .subscribe-button {
    width: 122px !important;
    }
}