.subcategory-form-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto; 
    width: 90%;
  }
  
  .subcategory-form-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* .subcategory-form-row {
    margin: 0 -10px; 
  }
  
  .subcategory-form-col {
    padding: 10px; 
  
    
  } */
  .subcategory-form-row {
    margin: 0 -5px; 
    display: flex; 
    flex-wrap: wrap; 
    gap: 15px; 
}

.subcategory-form-col {
    padding: 5px; 
    max-width: 48%; 
    /* flex: 0 0 48%;  */
    box-sizing: border-box; 
}
  .subcategory-form-label {
    font-size: 16px;
    font-weight: 600;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .subcategory-form-input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 5px;
    
  }
  
  .subcategory-form-input:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
  
  .subcategory-form-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    width: 100%;
  }
  
  .subcategory-form-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .subcategory-form-message {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: #28a745;
  }
  
  .subcategory-form-message.error {
    color: #dc3545;
  }
  
  .submitbuttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .button1,
  .button2 {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .button1 {
    background-color: #007bff;
    color: #fff;
  }
  
  .button2 {
    background-color: #ccc;
    color: #333;
  }
  
  .button1:hover,
  .button2:hover {
    opacity: 0.9;
  }
  
  /* For smaller screens */
  /* @media (max-width: 768px) {
    .subcategory-form-container {
      max-width: 100%;
      padding: 15px;
    }
  } */
  