.addcategory-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .category-input {
    padding: 10px;
    width:auto;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    height:50px !important;
  }

  
  .add-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .add-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .category-list {
    list-style: none;
    padding: 0;
  }
  
  .category-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  /* Responsive Styles */
  @media (min-width: 768px) {
    .addcategory-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  
    .add-button {
      grid-column: span 2;
      width: auto;
      width:275px;
    }
  }
  
  @media (min-width: 1024px) {
    .addcategory-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  