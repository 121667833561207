
input.checkbox-edu {
    width: auto;
}
.checkbox-container.col-10 {
    display: flex;
    column-gap: 30px;
    align-items: center;
    padding: 30px;
    justify-content: center;
}
label.c-f-s {
    font-size: 22px;
}
input.checkbox-edu {
    height: 23px;
    width: 24px;
}

button.quali-info-btn {
    padding: 8px 24px;
}
button.quali-info-btn {
    padding: 16px 30px;
    border-radius: 8px;
    background-color: #4976c9;
    color: white;
    border: none;
    font-size: 18px;
    margin-top: 30px;
}
.col-md-12.update-btn {
    display: flex;
    justify-content: center;
}
.container-fluid.qua-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gap-style{
    display: flex;
    row-gap: 10px;
}
.request-button
{
   

      font-size: 15px;
      color: black;
      font-weight: 500;
      cursor: pointer;
}
.request-button:hover
{
color: green;
}