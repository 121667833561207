/* AddDetail.css */

.container {
  margin: 20px auto;
}

.card-text {
  padding: 10px;
  font-weight: bold;
}

.mb-4 {
  margin-bottom: 1.5rem !important; /* Adjust this value for more/less gap */
}
