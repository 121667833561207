
.admin-png{
  width:70px !important;
}
.admin-login-card {
  margin-top: 20% !important;
  width: 60vh;
  height: 70vh;
  box-shadow: 0 0 40px rgb(51 51 51 / 10%);
}
.admin_input {
  width: 60%;
}
.admin_label {
  margin-top: 15px;
  margin-right: auto;
  font-weight: bold;
  font-size: 20px;
}
.admin_button {
  width: 60%;
  height: 35px;
}
.admin-main {
  overflow-y: hidden !important;
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 320px) and (max-width: 426px) {
  .admin-login-card {
    margin-top: 40% !important;
    width: 100%;
    height: 73vh;
  }
}

// @media (min-width: 768px) and (max-width: 1024px) {
//   .admin-login-card {
//     width: 60%;
//   }
// }
