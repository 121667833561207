.trainerSelect {
    width: 100%;
    border-radius: 5px;
    height: 37px;
  }
  
  .trainerInput {
    width:100%;
    display: flex;
  }
  .input-field2 {
    width: 100%;
    display: flex;
  }
  