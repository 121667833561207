.footer {
  background: linear-gradient(135deg, #0f0c29 0%, #302b63 50%, #24243e 100%);
  color: #ffffff;
  // padding: 3rem 0;
  font-family: "Poppins", sans-serif;

  .footer-logo-img {
    width: 240px;
    opacity: 0.9;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  .footer {
    min-width: none !important;
  }
  .footer-description {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #ddd;
  }

  .social-links {
    .social-icon-link {
      color: #fff;
      font-size: 24px;
      transition: color 0.3s ease, transform 0.3s ease;

      &:hover {
        color: #f39c12;
        transform: scale(1.2);
      }
    }
  }

  .footer-heading {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #f1f1f1;
  }

  .footer-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;

      .footer-link {
        color: #ccc;
        font-size: 1rem;
        text-decoration: none;
        transition: color 0.3s ease, transform 0.3s ease;

        &:hover {
          color: #f39c12;
          transform: translateX(5px);
        }
      }
    }
  }

  .footer-bottom-link {
    color: #ddd;
    font-size: 0.9rem;
    transition: color 0.3s ease;

    &:hover {
      color: #f39c12;
    }
  }

  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 1rem;
  }
}
footer.footer.text-light {
  padding-top: 1.5rem;
  padding-bottom: 5px;
}

footer.footer.text-light {
  min-width: 0px !important;
}

@media (max-width: 300px) {
}

@media (min-width: 301px) and (max-width: 480px) {
  .footer-top.align-items-center.p-3.row {
    display: flex !important;
    row-gap: 54px !important;
  }
  .footer-bottom.text-center.mt-4.row {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media (min-width: 481px) and (max-width: 576px) {
}

@media (min-width: 577px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 993px) and (max-width: 1200px) {
}

@media (min-width: 1201px) and (max-width: 1440px) {
}

@media (min-width: 1441px) {
}
.footer-contact.text-center.col-md-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-links.col-md-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
