.batchSelect {
  width: 100%;
  border-radius: 5px;
  height: 37px;
}

.batchSelectTime {
  width: 100%;
  border-radius: 5px;
  height: auto;
}

.batchInput {
  width: 100%;
  display: flex;
}

.input-field2 {
  width: 100%;
  display: flex;
}

.selectDropdown {
  width: 100%;
  // border-radius: 5px;
  // height: 37px;
  // width: 80% !important;
}
.container.mt-4.batch-create-container {
  padding: 90px !important;
}
.form-select.batch-create-form{
  width: 92% !important;
}