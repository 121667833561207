.header-container {
    background-color: #f8f9fa;
    width: 100%;
  }
  
  .header-text {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .site-logo {
    max-height: 80px;
  }
  
  /* .dropdown-hover .dropdown-menu {
    display: none;
  }
  
  .dropdown-hover:hover .dropdown-menu {
    display: block;
    position: absolute;
  } */
  
  .nav-link {
    cursor: pointer;
  }
  
  @media (max-width: 992px) {
    .header-text {
      font-size: 18px;
    }
  
    .site-logo {
      max-height: 60px;
    }
  
    .dropdown-hover {
      text-align: center;
    }
  }
  