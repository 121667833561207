.cardContainer {
  width: 40%;
  margin-bottom: 25px !important;
  margin-right: 20px !important;
  border-radius: 8px;
  box-shadow: 5px 5px 10px grey;
  transition: 0.4s ease-in-out;
}
.card_Col_Container {
  display: flex;
  flex-wrap: wrap;
}

.cardContainer:hover {
  transform: scale(1.02);
}
.cardTitle {
  font-size: 27px;
  color: var(--pr-color);
  font-weight: 600;
}
.pay_cardButton_1 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  line-height: 115%;
  background-color: #ff0000;
  box-shadow: 5px 10px 8px #cf9898;
  border: none;
  border-radius: 6px;
  color: white;
  margin-left: 75%;
  font-weight: 600;
  transition: 0.5s ease-in-out;
  width: 25%;
  height: 36px;
}
.pay_cardButton_1:hover {
  background-color: black;
  box-shadow: 5px 10px 18px #888888;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .cardContainer {
    width: 80%;
    margin-bottom: 25px;
    margin-left: 10%;
  }
  .pay_cardButton_1 {
    margin-left: 59%;
    width: 47%;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .cardTitle {
    font-size: 20px;
    font-weight: 800;
    // padding-left: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .cardContainer {
    margin-left: 4%;
  }
  .pay_cardButton_1 {
    margin-left: 59%;
    width: 40%;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
  }
}
// @media screen and (max-width:1329px){
//     .pay_cardButton1{
//         padding: 2px 3px ;
//         font-weight: 300;

//     }

// }

// @media screen  and (max-width:990px){
//     .cardContainer{
//         width:60%;
//         margin-bottom: 20px;
//         margin-left: 50px;

//     }

// }
// @media screen  and (max-width:375px){
//     .cardTitle {
//         font-size: 20px;
//         color: #0D4C92;
//         font-weight: 500;
//         margin-left: 20px !important;
//     }

// }
// @media screen  and (max-width:489px){
//     .filterContainer{
//         margin-top: 42px;
//         margin-bottom: 20px;
//     }
// }
