// // @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
// .dash-col {
//   margin-top: 40px;
//   padding-left: 10px;
//   padding-right: 10px;
// }
// // .Dash-cards {
// //   padding: 5px;
// //   justify-content: center;
// //   text-align: center;
// //   border-radius: 20px !important;
// //   box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
// //     0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
// //   font-weight: bolder;
// //   color: gray;
// //   cursor: pointer;

// // }

// .divider {
//   color: red !important;
// }
// .professional-Dash-cards {
//   display: flex;
//   justify-content: center;
//   padding: 2%;
//   border-radius: 20px;
//   box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
//     0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
//   font-style: italic;
//   font-weight: bolder;
//   font-size: larger;
//   color: gray;
// }
// .card-field {
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
// }
// // ADMIN DASHBOARD STYLES
// .AdminDash-cards {
//   padding: 5px;
//   justify-content: center;
//   text-align: center;
//   border-radius: 5px !important;
//   box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
//     0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
//   font-weight: bolder;
//   color: gray;
//   cursor: pointer;
// }

// .fieldsname {
//   text-align: center;
//   align-items: center;
//   font-size: 13px;
//   font-weight: 500;
//   color: #ff0000;
//   font-weight: 500;
// }
// .fieldscount {
//   text-align: center;
//   font-size: 15px;
//   color: rgba(48, 48, 48, 0.87);
//   font-weight: bolder;
// }

// // icon colors and size....
// .fieldicon1,
// .fieldicon2,
// .fieldicon3,
// .fieldicon4,
// .fieldicon5,
// .fieldicon6,
// .dashIcons {
//   color: #a3a1a1 !important;
//   padding: 0% !important;
//   font-size: 40px !important;
// }

// @media only screen and (max-width: 600px) {
//   .dash-col {
//     padding-bottom: 5%;
//   }
//   .admin-row {
//     display: block;
//   }
// }
// .FirstCardImage {
//   width: 100%;
//   height: 40vh;
// }
// // .FirstCard {
// //   // margin: 5%;
// //   background-color: rgb(233, 245, 241);
// // }
// .FirstCardButton {
//   border: none;
//   color: #5e9be2;
//   height: 12vh;
//   // background-color:  rgb(233, 245, 241);
//   background-color: white;
// }
// .DataPara {
//   font-weight: bold;
//   margin-top: 5%;
//   margin-left: 2%;
//   color: #4f7eb5;
// }
// .DataImage {
//   width: 100%;
//   height: 40vh;
//   border-radius: 5px 5px 0px 0px;
// }
// .ThirdCardPara {
//   font-weight: bold;
//   color: #4f7eb5;
//   font-size: 2rem;
//   text-align: center;
// }
// .DataCol {
//   padding: 3%;
// }
// .DataLastIcon {
//   color: #4f7eb5;
//   font-weight: bold;
//   margin-right: 3%;
// }
.dash-col {
  margin-top: 20px;
  border-radius: 10px;
}

.Dash-cards {
  padding: 10px;
  justify-content: center;
  text-align: center;
  border-radius: 20px !important;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  font-weight: bolder;
  color: gray;
  cursor: pointer;
  width: 100%; 
  height: 130px !important;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
}

.card-field {
  // display: flex;
  // justify-content: space-around;
  // align-items: center;
  // height: 100%;
}

.Afieldicon1,
.Afieldicon2,
.Afieldicon3,
.Afieldicon4,
.Afieldicon5,
.Afieldicon6,
.AdashIcons {
  color: white !important;
  padding: 0% !important;
  font-size: 50px !important;
}
  

.fieldscount1 {
  text-align: center;
  font-size: 30px; 
  color: #fff; 
  font-weight: 700;
  font-family: "Poppins", "Roboto", Arial, sans-serif; 
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
  line-height: 1.2; 
  margin-top: 5px;
  margin-bottom: 8px !important;
}

// .fieldscount1 {
//   text-align: center;
//   font-size: 29px;
//   color: white;
//   font-weight: bolder;
//   margin-top: 8px;
// }

// .fieldsname1 {
//   text-align: center;
//   align-items: center;
//   font-size: 30px;
//   font-weight: 500;
//   color: white;
//   font-weight: 500;
//     font-family: "Courier New", Courier, monospace;
// }

.fieldsname1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase; 
  letter-spacing: 1px; 
  font-family: "Poppins", "Roboto", Arial, sans-serif; 
}

.dashIcons {
  font-size: 40px;
  color: #a3a1a1 !important;
}


.fieldsname {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  height: 30px !important;
}

.fieldscount {
  text-align: center;
  font-size: 20px;
  color: rgba(48, 48, 48, 0.87);
  font-weight: bolder;
 


}

@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .Dash-cards {
    width: 55%; // Slightly reduced width
    height: 100px; // Maintain proportion
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .dash-col {
    margin-top: 10px;
  }

  .Dash-cards {
    width: 80%; 
    height: 100px; 
  }

  .fieldsname {
    font-size: 14px; 
  }

  .fieldscount {
    font-size: 18px;
  margin-bottom: 20px !important;

    
  }

  .dashIcons {
    font-size: 35px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .dash-col {
    margin-top: 15px; 
  }

  .Dash-cards {
    width: 90%; 
    height: 120px; 
    font-size: 16px; 
  }

  .fieldsname {
    font-size: 14px; 
  }

  .fieldscount {
    font-size: 16px;
  }

  .dashIcons {
    font-size: 30px;
  }
}

@media only screen and (max-width: 768px) {
  
  .Dash-cards {
    width: 150px; 
    height: 100px; 
    font-size: 14px; 
    padding: 8px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    margin: 15px !important;
  }

 
}


.FirstCardImage,
.DataImage {
  width: 100%;
  height: auto; 
}

.FirstCardButton {
  border: none;
  color: #5e9be2;
  height: 50px; 
  background-color: white;
}

.DataPara {
  font-weight: bold;
  margin: 5% 2%;
  color: #4f7eb5;
}

.DataLastIcon {
  color: #4f7eb5;
  font-weight: bold;
  margin-right: 3%;
}
