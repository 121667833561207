.detail-main{
    background-color: aliceblue;
    width: 100%;
    /* height: 30vh; */
}
.detail-company {
    align-items: center;
}
.btn-book{
    border: none;
    background-color: rgb(40, 191, 241);
    border-radius: 10px;
    height: 6vh;
    width:40%;
}
.btn-follow{
    border: none;
    background-color: rgb(26, 238, 128);
    border-radius: 10px;
    height: 6vh;
    width:40%;
}
.detail-content{
    margin-top: 2%;
    font-family: sans-serif !important;
    line-height: 30px;
    text-align: justify;
}
.detail-info{

    height: 55vh;
    background-color: rgb(239, 241, 241);
}

.detail-info-content{
    line-height: 35px;
    // font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: medium;
    display: flex;
    color: gray;
    
}
.detail-card{
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    height: 90% !important;
    border-radius: 12px !important;
    cursor: pointer;
    transition-duration: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-int{
    border-color: rgb(202, 198, 198);
    border-radius: 20px ;
    width:80%
}