.FilterItems{
    text-align: center;
    padding-top: 2%;
    padding-bottom: 22%;
 }
 .Filterspart{
     padding-top: 3%;
 }
 
 .Filterpart{
    border: 1px solid red;
    width:80%;
    height: auto;
    border-right: 5%;
    margin-left: 9% ;   
 }
 
 .CartList2{
     text-align: center;
     padding: 5%;
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
     flex-wrap: wrap;
 }  
 .row {
     --bs-gutter-x: 0rem !important;
     --bs-gutter-y: 0;
     display: flex ;
     /* justify-content: center; */
 }   
 @media only screen and (max-width: 1024px) {
     .CartList1{
         width:100%;
         height: auto;
        
         
     }
 }
 
 