


  li{
    display: flex;
    align-items: baseline;
  }

.tick-icon {
    font-size: 25px;
    margin-right: 10px;
  color: #007bff !important;
}
h4{
  font-weight: bold;
}

.icon-container {
  display: flex;
  align-items: center;
}
.dynamic-container-features{
  border: 1px solid rgb(219, 217, 217);
  padding-left: 35px !important;

}
li.dynamic-container-features:hover {
background-color: rgb(215, 216, 216) !important;
}

ul.list-unstyled {
  display: flex;
  flex-direction: column;
  // row-gap: 20px;
}
li.dynamic-container-features {
  height: 140px;
}
.right-icon{
  color: red;
  font-size: 60px;
}
.content-display {
  padding-left: 45px !important;
}

.our-service-text{
  margin: 0;
  margin-top: 4px;
  font-weight:400;
  font-size: 14px !important;
  color: rgb(28, 28, 28);
}



h2 {
  font-size: 3rem;
  color: #1f3b5b;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 800;
}

.dynamic-container-features {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-left: 5px solid transparent;
}

.dynamic-container-features:hover {
  transform: translateY(-5px);
  background: #e9f1fa;
  border-left: 5px solid #007bff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.icon-container .right-icon {
  font-size: 2.5rem;
  color: #007bff;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.dynamic-container-features:hover .right-icon {
  color: #ff4081;
  transform: scale(1.1);
}

.content-display {
  background: white;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #e3e9f1;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.tick-icon {
  font-size: 1.8rem;
  color: #28a745;
  margin-right: 15px;
}

.feature-contentt h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #1f3b5b;
}

.feature-contentt .cont {
  font-size: 1.1rem;
  color: #5f6f81;
}

.list-unstyled li {
  padding: 20px 0;
  border-bottom: 1px solid #f1f3f5;
}

.list-unstyled li:last-child {
  border-bottom: none;
}

.feature-content h4 {
  font-weight: 700;
  color: #333d51;
}

.feature-content p {
  font-size: 1.05rem;
  color: #6c757d;
}

li.dynamic-container-features {
  height: 180px;
}
.feature-title{
  color: #ff8361 !important;
  
}

h4.mb-1.featur-left-title {
  color: #1372d1;
 font-weight: 600
}

.content-display-dark{
  background-color: #222222;
  padding-left: 45px !important;
}
.dynamic-container-features-dark{
  cursor: pointer;
  padding: 10px;
  background: transparent;
  border-left: 10px solid transparent; 
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background 0.4s ease;
  border: 1px solid white;
  border-bottom: 1px solid white;

}
.dynamic-container-features-dark.active{
  background: #d6e9f2; 
  border-left: 10px solid red; 
}
li.dynamic-container-features-dark {
  height: 180px;
}
.our-service-text-dark{
    margin: 0;
    margin-top: 4px;
    font-weight:400;
    font-size: 14px !important;
    color: #6c757d;
  
}