/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Navbar Container */
.custom-navbar {
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    padding: 14px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1000;
    border-radius: 15px;
}

/* Logo and User Info Container */
.custom-navbar-logo {
    display: flex;
    align-items: center;
}

.custom-navbar-logo-img {
    width: 70px;
    height: 70px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.custom-navbar-logo-img:hover {
    transform: scale(1.1);
}

.custom-navbar-user-info {
    margin-left: 20px;
    color: #ecf0f1;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.custom-navbar-user-role {
    font-size: 18px;
    font-weight: 600;
}

.custom-navbar-user-id {
    font-size: 14px;
    color: #bdc3c7;
}

/* Navigation Links */
.custom-navbar-links {
    display: flex;
    align-items: center;
    /* gap: 30px; */
}

.custom-nav-link {
    color: #ecf0f1;
    font-size: 16px;
    display: flex;
    align-items: center;
    /* gap: 10px; */
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease-in-out;
    padding: 10px 20px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
}

.custom-nav-link:hover {
    background: white;
    color: #4b6cb7;
}

.custom-nav-icon {
    font-size: 22px;
}

/* Dropdown Menu */
.custom-dropdown {
    position: relative;
    width: 150px;
}

.custom-dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #34495e;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    width: 300px;
}

.custom-dropdown:hover .custom-dropdown-content {
    display: block;
}

.custom-dropdown-item {
    padding: 15px 20px;
    color: #ecf0f1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.custom-dropdown-item:hover {
    background-color:white;
    color: black;
}

.custom-dropdown-description {
    font-size: 14px;
    color: #bdc3c7;
    margin-left: 5px;
    margin-top: 5px;
    width: 100%;
}

/* Navbar Interaction Animations */
.custom-nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #f39c12;
    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);
}

.custom-nav-link:hover::after {
    width: 100%;
}
