.carousel_Admin {
  width: 30%;
  height: 30vh;
  margin-top: 10%;
  /* display: flex;
  justify-content: center;
  align-content: center; */
}
.card_container_freelancingAdmin {
  display: flex;
  justify-content: center;
}
.carouselAdminButton {
  background-color: #ff1e1e;
  box-shadow: 5px 10px 8px #cf9898;
  color: white;
  width: 77px;
  border: none;
  height: 10px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 16px 18px;
}
