.carouselPlayimage {
  height: 70vh;
}
.carouselHeading_title {
  font-size: 30px;
}
/* .carouselContainer_freelancing {
  height: 70vh;
} */

@media screen and (min-width: 320px) and (max-width: 425px) {
  .carouselPlayimage {
    height: 40vh;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .carouselPlayimage {
    height: 50vh;
  }
}
