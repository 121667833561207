.subcategory-container {
  padding: 20px;
}

.subcategory-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.subcategory-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.subcategory-grid .react-select__control {
  min-width: 200px;
}

.subcategory-grid input.subcategory-input {
  width: 100%;
  max-width: 250px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  font-size: 16px;
}

.subcategory-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.subcategory-list li {
  font-size: 16px;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.add-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button:disabled {
  background-color: #dcdcdc;
  cursor: not-allowed;
}

.add-button:hover:enabled {
  background-color: #45a049;
}

.message {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.subcategory-container .react-select__control {
  min-width: 200px;
}

.subcategory-container .react-select__menu {
  max-height: 200px;
  overflow-y: auto;
}

.subcategory-container .subcategory-grid input {
  max-width: 250px;
  width: 100%;
}

.subcategory-list {
  margin-top: 20px;
}

.subcategory-list li {
  padding: 8px;
  background-color: #f8f8f8;
  margin: 5px 0;
  border-radius: 4px;
}

.subcategory-list li:hover {
  background-color: #e2e2e2;
}
