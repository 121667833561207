.Add1{
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content:space-evenly;
    align-items: baseline; */
    align-items: baseline;
  
}
.Add3{
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline;
    padding-left: 0%; */
    align-items: baseline;
}
.Add4{
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline; */
    align-items: baseline;
}
.Add2{
   
    padding-left: 17%;
}
.Add5{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3%;
}
@media  screen and (max-width:425px) {
    .AddList{
        text-align: center;
        
    }
    .Add2{
        padding-right: 17%;
    }
   .Add1{
    width:"32";
   }
}
@media screen and (min-width:320px) and (max-width:425px){
    .Add5 .bn{
        width:35%;
    }
    
}
@media screen and (min-width:1024px) and (max-width:2560px) {
    .im{
        width:38vw;
    }
    .Ad{
        min-width: 80%;
    }
    .AddLists{
        width: 100%;
        height:auto;
        
    }
    .AddList{
        font-size: 1vw;
      
        
    }
    .Ads{
        min-width: 32%;
    }
   
}
.AddedList{
    text-align: justify;
    align-items: normal;
    display: flex;
    justify-content: space-evenly;
}

    