.start_clg{
    text-align: center;
}
.clg_carousel{
    display: flex;
    justify-content: center;
}
.card_clg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
  }
  .clg_card_content_divi {
    position: relative;
    width: 50%;
    height: 60%;
    transition: 0.5s;
    margin-bottom: 10%;
    border-radius: 5%;
    box-shadow:  25px 10px rgb(160, 153, 151);
  }
  .clg_card_content_divi:hover {
    cursor: pointer;
    transform: scaleX(1.05);
    transform: rotate(1.2);
    box-shadow: 3px 3px #FF0000;
  }
  .card_img_cad {
    display: block;
    height: 100%;
    width: 100%;
    height: auto;
    border-radius: 5%;
  }
  .clg_txt_card_content_card {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(232, 38, 38, 0.354);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
    border-radius: 5%;
  }
  .clg_card_content_divi:hover .clg_txt_card_content_card {
    height: 100%;
  }
  .txt_card {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: auto;
    font-size: 40px;
  }
  .card_start_clg{
    display: flex;
    justify-content: center;
    text-align: center;
    transition:0.5s;
    padding-bottom: 5%;
  }
  .clg_hover_card_{
    transition: 0.5s;
    box-shadow:  10px 7px #FF0000;
  }
  .clg_hover_card_clr{
    transform: 0.5s;
    box-shadow:  10px 7px rgb(72, 68, 68);
  }
  .clg_flex_card{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 3%;
}
.para_clg{
    display: flex;
    justify-content: center ;
    align-items: center;
    border: none;
    padding-left: 1%;
    transition: 0.5s;
}
.clg_card_hov{
    transition: 0.5s;
}
.clg_card_hov:hover{
    transform: scale(1.05);
    box-shadow: 5px 3px 10px 7px rgb(72, 68, 68);
}