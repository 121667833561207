.course-carousel {
    padding: 50px 0;
    margin-top: 35px;
    background-color: #f9f9f9;
  }
  
  .course-slider {
    margin: 0 auto;
  }
  
  .course-card {
    position: relative;
    width: 100%;
    max-width: 350px;
    height: 470px; 
    perspective: 1000px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  img.card-img-top.cou-image {
    width: 100%;
    height: 190px;
}
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px; 
  }
  
  .flip-card-front {
    z-index: 2; 
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
    background-color: #fff; 
  }
  
  .flipped .flip-card-inner {
    transform: rotateY(180deg); 
  }
  
  .flip-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }
  
  .flip-icon:hover {
    background-color: #f1f1f1;
  }
  
  .back-button {
    margin-top: 10px;
    background-color: #6c757d;
  }
  
  .bestseller-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f39c12; 
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }
  

  .price-button {
    position: relative;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    padding: 10px 20px; 
    border-radius: 5px;
    background-color: #28a745; 
    color: white;
  }
  
  .price-button:focus {
    outline: none; 
  }
  
 
  @keyframes pulse {
    0% {
      transform: scale(1);
      background-color: #28a745; 
    }
    50% {
      transform: scale(1.05);
      background-color: #218838; 
    }
    100% {
      transform: scale(1); 
      background-color: #28a745;
    }
  }
  
  .price-button {
    animation: pulse 1.5s infinite; 
  }
  
  .price-button:hover {
    background-color: #218838;
  }
  
  .course-title.card-title.h5 {
    color: #0066cc;
}
.\.course-carousel.container-fluid{
  background-color: #f0f4f7!important;
  padding: 50px;
}
.\.course-carousel-dark.container-fluid{
  background-color: #222;
  padding: 50px;
}

@media (min-width: 300px) and (max-width: 415px) { 
  .\.course-carousel.container-fluid{
    background-color: #f0f4f7!important;
    padding: 0px 20px;
  }
  .\.course-carousel-dark.container-fluid{
    background-color: #222;
    padding: 0px;
  }
  button.slick-arrow.slick-next {
    margin-right: 26px;
  }
  button.slick-arrow.slick-prev {
    margin-left: 20px;
  }
  .course-card-body.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}
}