*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
  body{
    font-family: 'Outfit';
  }
.contact-container{
  height: 81vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.contact-left-title h2 {
  font-weight: 600;
  font-size: 40px;  
  margin-bottom: 1px;
  padding-top: 10vh;
}
.dark-hr {
  border: none; /* Remove default border */
  height: 5px; /* Adjust the height as needed */
  background-color: black; /* Set the background color to black */
  margin: 20px 0; /* Optional: Add some margin for spacing */
  border-radius: 10px;
  width: 120px;
}
.contact-left {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
}

.contact-inputs {
  height: 50px;
  width: 400px; /* Make inputs take full width */
  max-width: 500px; /* Limit max width */
  margin-bottom: 15px; /* Add some spacing between inputs */
  border: none;
  outline: none;
  padding-left: 25px;
  font-weight: 500;
  color: #666;
}

.contact-left textarea {
  height: 140px;
  padding-top: 25px;
  border-radius: 20px;
  resize: none; /* Optional: prevent resizing */
}

.contact-inputs:focus {
  border: 2px solid #ff994f;
}

.contact-inputs::placeholder {
  color: #a9a9a9;
}

// .contact-left button {
//   align-items: center;
//   display: flex;
//   justify-content: center;
//   padding: 15px 30px; /* Correct padding syntax */
//   font-size: 16px;
//   border-radius: 50px;
//   background: red;
//   color: white; /* Ensure text color contrasts with button background */
//   border: none; /* Remove default border */
//   cursor: pointer; /* Change cursor to pointer */
// }
.contact-right {
  position: relative; /* Set position for the video to be absolute */
  overflow: hidden; /* Hide any overflow from the video */
  height: 100vh;
  width: 50%;
}

.background-video {
  position: absolute; /* Position the video absolutely */
  left: 0; /* Align to the left */
  width: 100%; /* Make video full width */
  height: 100%; /* Make video full height */
  object-fit: cover; /* Ensure the video covers the area without distortion */
  z-index: -1; /* Send video to the back */
  background-size: cover;
}
.middle-container{
  width: 450px;
  height: 200px;
  border-style: hidden;
  box-shadow: 10px 10px 600px 10px violet inset;
  position: relative;
  transform: translate(-10%, -140%);
  opacity: 0.3s;
  transition: transform 0.5s ease, opacity 0.5s ease;    
}
.middle-container h3{
  margin-top: 0px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  font-size: 50px;
  font-weight: 700;
  transform: translate(0%, 30%);
  transition: transform 0.5s ease-in-out;
}