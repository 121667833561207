.classic {
    margin-right: 6px;
    margin-top: 4%;
  }
  .classics {
    margin-right: 6px;
  }
  .package_page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subscriber-button-container {
    margin-top: 100px;
    margin-left: 1100px;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
  }
  .form-select {
    width: 60%;
    padding: 0.5rem;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  