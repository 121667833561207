/* .content-profile {
  background-color: rgb(228, 226, 223);
    background-image: linear-gradient(to right, rgb(204, 183, 183) , rgb(149, 149, 134));
} */
.profile-img{
  width: 85px;
  height: 75px;
  line-height: 64px;
  font-size: 32px;
}
.profile-input {
  width: 80% !important;
  border-radius: 5px;
  // border: 1px solid gray;
  height: 37px;
}

.profile-select {
  width: 80% !important;
  border-radius: 5px;
  // border: 1px solid gray;
  height: 37px;
}
.profile-desp {
  width: 80% !important;
  height: 15vh;
  border-radius: 5px;
  border: 1px solid gray;
}
.button-profile {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
}
.heading-profile{
margin-top: 5px;
font-weight:700;
padding-left: 0px !important;
}
.profile-submit {
  border-radius: 20px !important;
  border: none;
  color: white;
  width: 100px;
  height: 5vh;
}
.profile-submit:hover {
  box-shadow: 0 5px 8px 0 rgb(72, 72, 74) !important;
}

input.employer-input-field {
  height: 45px;
  width: 90%;
}
.employer-input-field:focus{
  border: 1px solid blue !important;
}
.employer-input-field{
padding-left: 10px !important;
}
.mt-2.form-space-alignment.row {
  row-gap: 18px;
}
.mb-4.profile-image-container.row {
  display: flex;
  justify-content: center;
}
input.profile-file {
  width: 40%;
}
.px-4.mx-5.col-md-8.col-sm-24.col-24 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-container-profile {
  display: flex;
  justify-content: center;
}

.profile-reset {
  border-radius: 20px !important;
  border: none;
  color: white;
  width: 100px;
  height: 5vh;
}
.profile-reset:hover {
  box-shadow: 0 5px 8px 0 rgb(72, 72, 74) !important;
}
.text-danger {
  position: absolute;
  font-size: 14px;
}
.profile-file{
  // margin-left: -29%;
}

@media screen and (max-width:991.78px){
  .text-danger{
    font-size: 14px;
    position:unset !important;
  }
}
.heading-profile{
 color:  rgb(8, 56, 113);
}
