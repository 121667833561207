.skill_image {
  // height: 300px;
  width: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
}
@media (max-width: 425px) {
  .skill_image {
    height: 200px;
  }
}
.skill_banner {
  position: relative;
}
.grow_text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  position: absolute;
  top: 0;
  // bottom: 0;
  // left: 10%;
  // right: 0;
  height: 40%;
  margin: auto;
  display: flex;
}
.fontdesign {
  font-family: -webkit-body;
  font-size: 45px;
  padding-bottom: 30px;
  text-shadow: 2px 2px gray;
  color: whitesmoke;
}
.car {
  z-index: 1;
}
.tit {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  position: absolute;
  color: black;
  top: 0;
  bottom: 0;
  left: 10%;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: flex-start;
}
.Int-popular-head {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 0;
}
.Int-col {
  width: 100%;
}
.Inttitle {
  text-align: center;
  margin-top: 5%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px 20px;
  border: 1px solid #e6e2e2;
  cursor: pointer;
  transition-duration: 0.4s;
  font-size: small;
  font-weight: 600;
  color: rgb(85, 81, 81);
}
.Inttitle:hover {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
}

.button {
  background-color: #ffd8d8;
  border: none;
  // color: #ff0000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  white-space: nowrap;
}

.button:hover {
  background-color: #f1f1f1;
}

.logo {
  height: 80px;
  // align-items: center;
}
.star {
  height: 15px;
  margin-right: 15px;
  margin-left: 15px;
}
.staralign {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.cardrow {
  display: flex;
  flex-flow: wrap;
  min-width: 0;
  justify-content: center;
}
.student_professional_button {
  display: flex;
  justify-content: center;
}
.skills_card {
  display: flex;
  justify-content: center;
}

// TRCHNOLOGY-CARDS-DESIGN

.service_cards {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .service_card {
    height: 400px;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.418);
    max-width: 250px;
    min-width: 260px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: all 0.4s ease;
    margin: 0 10px;

    &:before {
      height: 180px;
      width: calc(100% + 100px);
      content: "";
      position: absolute;
      background-image: linear-gradient(
        to top,
        #ff0000 0%,
        rgba(238, 42, 42, 0.568) 100%
      );
      border-radius: 4px 4px 100% 100%;
      transition: all 0.4s ease-out;
      top: 0;
    }

    .close {
      width: 18px;
      height: 18px;
      position: absolute;
      z-index: 2;
      cursor: pointer;
      background-image: url("https://rafaelalvucas.github.io/assets/icons/misc/cross.svg");
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
      top: 0;
      right: 0;
      margin: 10px;
      padding: 5px;
      transition: all 0.2s ease;

      &:hover {
        background-size: 100%;
        opacity: 0.8;
      }
    }

    .arrow {
      display: none;
    }

    article {
      z-index: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;

      h2 {
        color: white;
        margin: 0;
        padding: 40px 20px 10px 20px;
        font-weight: 500;
        font-size: 24px;
        letter-spacing: 0.5px;
      }

      .title {
        color: white;
        padding: 10px 20px;
        letter-spacing: 0.8px;
        transition: all 0.4s ease;
      }

      .desc_technology {
        padding: 10px 30px;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        color: #000000;
        height: 90px;
        transition: all 0.4s ease-out;
      }

      .pic {
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 100%;
        margin: 20px 0;
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.685);
        transition: all 0.6s ease;

        img {
          width: 100%;
          // -webkit-filter: grayscale(90%);
          // filter: grayscale(90%);
        }
      }
    }

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);

      &:before {
        height: 100%;
        border-radius: 4px;
      }

      .desc_technology {
        color: white;
      }

      .pic {
        box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3);
        img {
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }
    }

    &.closed {
      min-width: 120px;
      max-width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.6s ease;
      cursor: pointer;

      .title,
      .desc,
      .actions,
      .close {
        display: none;
      }

      h2 {
        padding: 0;
        height: 100%;
        transform: rotate(-90deg);
        width: 440px;
        z-index: 2;
        transition: all 0.6s ease;
      }

      .pic {
        border-radius: 100%;
        height: 400px;
        width: 400px;
        position: absolute;
        top: -20px;
        margin: 0;
        box-shadow: 0;
        transition: all 0.6s ease;

        img {
          object-fit: cover;
          height: 100%;
          transform: translateY(20px);
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.5;
          z-index: 1;
          transition: all 0.4s ease;
          transform: translateY(20px);
        }
      }

      &:before {
        height: 100%;
        border-radius: 4px;
      }

      .arrow {
        width: 18px;
        height: 18px;
        position: absolute;
        z-index: 2;
        cursor: pointer;
        bottom: 15px;
        padding: 5px;
        display: flex;
        justify-content: center;
        background-image: url("https://rafaelavlucas.github.io/assets/icons/misc/expand.svg");
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center;
        transition: all 0.2s ease;
      }

      &:hover {
        .arrow {
          background-size: 100%;
          opacity: 0.6;
        }
      }
    }
  }
}

// SKILL-CARD
.skill_content_card {
  display: flex;
  justify-content: center;
}

// VIEW-MORE-BUTTON
.viewMore-skills-button {
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  box-shadow: 3px 3px 3px rgb(77, 76, 76);
}
.viewMore-skills {
  display: flex;
  justify-content: center;
}

.viewMore-skills-button:hover {
  box-shadow: 3px 3px 15px rgb(0, 0, 0);
}
.viewMore-skills-learning{
  display: flex;
  justify-content: center;
}

.priceContainer { 
  position: absolute;
  top: 15%;
  left: 40%;
}
.orginalPrice {
  color: #ff0000;
  font-style:italic;
}

.discountPrice {
  color:black;
  font-weight: 500;
  font-size: larger;  
}

.resetButtonCol {
  display: flex;
  justify-content: end;
}


.resetButton {
  width: 70px;  
  height: 25px;
  display: flex;
  align-items: center;
}

.title-input-stu {
  width: 200%;
}

.learning-container {
  display: flex;
  justify-content: center;
}
.ant-modal-footer {
  display: flex;
  justify-content: space-between;
}