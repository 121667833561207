.service-image {
  // height: 50px;
  width: 100%;
  z-index: -1;
}
.fading {
  font-family: fantasy;
  font-size: 5vw;
  animation-name: fade-in;
  animation-duration: 3s;
  color: rgb(212, 221, 221);
  text-shadow: 0px 2px 4px -1px rgb(0 0 0 / 80%);
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10%;
  right: 0;
  height: 40%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.serviceheading {
  display: flex;
  justify-content: flex-start;
  font-size: 22px;
  font-weight: 700;
  color: rgb(63, 63, 63);
}
.servicecard {
  border-radius: 6px 20px !important;
  background-color: #fff;
  height: 100%;
  max-width: 25em;
  border-radius: 0.6em;
  padding: 10px;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  flex-wrap: wrap;
}
.servicecard:hover {
  transform: scale(1.1);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  // background-color: rgba(165, 160, 160, 0.397);
}
