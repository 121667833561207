.category-container {
    padding: 0 15px;
    
  }
  
  .category-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .category-content {
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    text-align: left; 
    border: 2px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
    width: 100%; 
  }
  
  .category-content:hover {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .category-info {
    margin-left: 15px; 
  }
  
  .category-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .category-subtitle {
    font-size: 1rem;
    color: #666;
  }
  
  .category-logo {
    font-size: 2rem;
    color: #007bff;
    flex-shrink: 0; 
  }
  
 
  .slick-slide {
    padding: 0 10px;
  }
  h1.text-center.title {
    font-size: 38px;
    font-weight: bolder;
}
  .slick-prev,
  .slick-next {
    z-index: 1;
    color: #007bff;
  }
  
  .slick-prev {
    left: -30px;
  }
  
  .slick-next {
    right: -30px;
  }
  
  .slick-dots {
    bottom: -30px;
  }
  
  .slick-dots li button:before {
    color: #007bff;
  }
  
  .slick-dots li.slick-active button:before {
    color: #0056b3;
  }
  .category-container{
    padding-top: 100px!important;
  }
  .sub-h{
    font-size: 18px;
    color: #0056b3;
    font-weight: bold;
  }