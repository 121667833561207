.first_txt {
  font-size: 350%;
  background-color: rgb(142, 139, 139);
  margin-top: 0%;
  margin-bottom: 0%;
}
/* .first_Nav{
    display: flex;
    justify-content: center;
} */
.row {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
  padding-right: 0px;
  padding-left: 0px;
}
.Cad_bg {
  background-color: rgb(145, 144, 144);
}
/* .pg_navbar{

} */
.Caro_img {
  width: 100%;
  height: 60vh;
}
.nav_content {
  display: flex;
  justify-content: space-between;
}
.dropdown_contents {
  max-height: 90px;
  width: 70%;
  display: flex;
  justify-content: space-around;
}
.second_content {
  display: flex;
  justify-content: center;
  margin-top: 6%;
}
.second_content_card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
}
/* .Cad_content{
    width:70%;
    margin-bottom: 6%;
    cursor: pointer;
} */

.Cad_content {
  position: relative;
  width: 80%;
  transition: 0.5s;
  margin-bottom: 10%;
  border-radius: 5%;
}
.Cad_content:hover {
  cursor: pointer;
  transform: scaleX(1.05);
  transform: rotate(1.2);
  box-shadow: 3px 3px rgb(244, 8, 8);
}

.pg_image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5%;
}

.txt_content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(81, 79, 79, 0.354);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  border-radius: 5%;
}

.Cad_content:hover .txt_content {
  height: 100%;
}

.txt {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.Third_con_ca {
  width: 18rem;
  height: 18rem;
  cursor: pointer;
  transition: 0.5s;
}
.Third_con_ca:hover {
  background-color: rgb(247, 242, 242);
  transform: scale(1);
  box-shadow: 1px 2px 2px 1px rgb(185, 184, 184);
}
.map_fuc_im {
  width: 30%;
  height: 30%;
  display: flex;
  margin-left: 33%;
  margin-top: 2%;
  border-radius: 50%;
}
.Third_con_txt {
  margin-top: 0%;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
}
.Third_con {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  margin-top: 5%;
}
.usefeed {
  margin-top: 5%;
  letter-spacing: 5px;
  text-decoration: underline;
  justify-content: center;
  display: flex;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .Third_con_ca {
    width: 15rem;
  }
}

.Third_con_ca1 {
  width: 15rem;
  height: 17rem;
  cursor: pointer;
}
.Third_con_ca1:hover {
  color: red;
  background-color: rgb(225, 224, 224);
  transform: rotate(1.2);
  box-shadow: 3px 1px rgb(168, 167, 167);
}
/* .map_fuc_im1:hover{
    /* opacity:1;
 } */

.map_fuc_im1 {
  /* opacity: 0.5; */
  cursor: pointer;
}
.collec_title {
  margin-top: 0%;
}
.Collec_ico {
  margin-top: 57%;
  color: red;
}
.bt_content {
  transition: 0.5s;
  margin-top: 3%;
}
.bt_content:hover {
  transform: scale(1.05);
  box-shadow: 5px 5px px 0px rgb(113, 112, 112);
}
@media (min-width: 768px) and (max-width: 1023px) {
  .a-box {
    width: 178px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .img-container {
    width: 142px;
  }
}
.btn_content {
  transition: 0.5s;
}
.btn_content:hover {
  transform: scaleX(1.1);
}
.a-box {
  display: inline-block;
  width: 90%;
  text-align: center;
}

.img-container {
  height: 61%;
  width: 100%;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.img-container img {
  transform: skew(0deg, -13deg);
  height: 250px;
  margin: -35px 0px 0px -70px;
  transition: 2s;
}

.inner-skew {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  transform: skew(0deg, 13deg);
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: #c8c2c2;
  height: 250px;
  width: 200px;
  cursor: pointer;
}
.a-box:hover img {
  transform: scale(1.5);
  /* transform: rotateX(7deg) translateY(-6px); */
  /* transform: rotateX(2deg) translateY(-3px) scale(1.05); */
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #f2fafa;
  margin: -120px 0px 0px 0px;
  line-height: 19px;
  font-size: 14px;
  cursor: pointer;
}
.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #787a7a;
  font-size: 18px;
}
.collection_content {
  display: flex;
  justify-content: center;
}
.Fourth_content {
  margin-top: 4%;
}
.collection_column {
  margin-top: 3%;
  width: 15%;
}
