.career-img{
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    width:100%;
}
.career-main{
    font-weight: bold;
    font-size: 2em;
    color:rgb(4, 4, 4);
    margin-top:-50px;
}
.career-main-content{
    font-size: 18px;
    font-weight: 500;
    color: #000;
    font-family: sans-serif !important;
    line-height: 1.65;
    margin-top: 30px;
}
.career-btn {
    color: aliceblue;
    background-color: rgb(24, 150, 188);
    border: none;
    height: 6vh;
    border-radius: 1px;
    font-size: larger;
  }
.career-content{
    background-color: rgb(241, 243, 243);
}  
.career-search{
    width:50%;
    border-radius: 5px;
    display: flex;
    justify-content: left;
}
.career-input{
    width:90%;
    border:none;
}
.career-text{
    width:87%;
    border:none;
}
.btn-career{
    color: aliceblue;
    background-color: rgb(24, 150, 188);
    border: none;
    height: 6vh;
    border-radius: 5px;
    font-size: larger;
    width:150px;
}