#company-features-section {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    overflow: hidden;
 
   
  
    &.animate {
      opacity: 1;
      transform: translateX(0);
    }
   
  
    .img-fluid {
     max-width: 76%;
      height: auto;
      transition: transform 0.6s ease;
      transform: translateX(-50px);
      position: relative;
    }
    .attribute{
        position: absolute;
        bottom: 0;
    }
  
    &.animate .img-fluid {
      transform: translateX(0);
    }
  
    ul {
      list-style: none;
      padding: 0;
  
      li {
        margin: 20px 0;
        font-size: 16px;
        display: flex;
        align-items: flex-start;
        
        .tick-icon {
          color: #52c41a; 
          margin-right: 10px;
          font-size: 22px; 
        }
  
        .feature-content {
          h4 {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 5px 0;
          }
  
          p {
            margin-top: 6px;
            line-height: 1.4;
          
          }
        }
      }
    }
  }
  