.number-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    display: flex;
    z-index: 2;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -56px;
  }
  
  .line-connector {
    position: absolute;
    left: -42px;
    top: 50%;
    height: 102%;
    width: 2px;
    background-color: #007bff;
}
  
  .tick-icon {
    color: #007bff;
    margin-left: 10px;
  }
  
  .col-md-6.role-content {
    box-sizing: border-box;
    padding: 0 20px;
}
.role-cont {
  margin-left: 50px;
  /* border-left: 1px solid rgb(198, 198, 198); */
  /* padding-bottom: 10px; */
}

button.btn.btn-primary.role-section {
  /* padding: 14px 70px; */
  /* margin-left: 100px; */
width: 25%;
margin-left: auto;
  
}
span.ms-4.roles {
  margin-left: 44px !important;
  margin-top: 3px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -1px;
}

@media (max-width: 900px) {
  span.ms-4.roles {
    font-size: 10px; /* Adjust font size for tablet */
    margin-left: 30px !important; /* Adjust margin for tablet */
  }
}
h4.im-text {
  font-weight: bolder;
  color: blue;
  font-size: 30px;
}
.col-md-6.right-role-cont {
  padding-top: 130px;
}
