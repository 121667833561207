.course-create-form {
    /* max-width: 900px; */
    /* margin: 20px; */
margin:35px 50px 25px;

  }

  
  .form-row {
    /* display: flex; */
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    margin-right: 40px; 
  }
  
  .form-group:last-child {
    margin-right: 0;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="number"],
  textarea,
  .ant-select,
  .ant-picker,
  select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .course-create-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 15%;
    border-radius: 7px;

  }
  
.course-create-btn:hover {
    background-color: #0056b3;
    /* width:200px !important */
  }
  
  .add-learning-btn{
    background-color: rgb(66, 197, 66);
    color:white;
    border-radius: 8px;
    border:none;
  
  }
  .learning-input{
    width:350px !important;
  }
  .technology-input{
    width:350px !important;
  }
  input#formTitle,textarea#formDescription {
    height: 45px;
    width: 95%;
}
input#formSector,input#formDomain,input#formCategory,input#formSubcategory,input#formOriginalPrice,input#formOfferPrice{
  height: 45px;
}
input#formLevel{
  width: 95%;
  height: 45px;
}
.course-create-container.container{
  box-sizing: border-box;
  padding: 90px;
}