.counselling-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .form-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input, select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  
  /* Responsive Design for larger screens */
  @media (min-width: 768px) {
    .form-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .form-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .submit-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  