.tab-titles {
  display: flex;
  cursor: pointer;
  padding: 10px;
  font-family: "Roboto", serif;
  font-size: 20px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  position: relative;
 
}

.tab-title {
  flex: 1;
  padding: 10px;
  text-align: center;
  position: relative;
  transition: color 0.3s;
}

.tab-title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  height: 2px;
  width: 80%;
  background-color: #007bff;
  transform: translateX(-50%);
  opacity: 0;
}

.tab-title:hover {
  color: #007bff;
}

.tab-title:hover::after {
  opacity: 1;
}

.tab-title.active {
  color: #007bff;
  font-weight: bold;
}

.tab-title.active::after {
  opacity: 1;
}

.tab-contents {
  padding: 20px;

}
.col-9.course-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.col-9.course-detail-container {
  margin-top: 20px;
}
.col-3.apply-card {
  border: 1px solid rgb(222, 221, 221);
  border-radius: 14px;
  margin-top: 22px;
  height: auto;
  padding: 30px;
}
h1.course-title-card {
  font-family: "Roboto";
 font-size: 28px !important;

}
p.course-date {
  font-size: 18px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
p.course-tech {
  font-size: 18px;
}

p.course-instructor {
  font-size: 18px;
}
h4.section-title {
  display: flex;
  column-gap: 10px;
}
button.landing-apply-btn {
  padding: 12px 60px;
  border: none;
  background-color: #007bff;
  color: white;
  font-family: poppins;
}
.apply-button-wrapper {
  display: flex;
  justify-content: center;
}

li.day-item {
  // display: flex;
  color: 13px;
  column-gap: 10px;
  font-size: 16px;
}
ul.days-list {
  display: flex;
  row-gap: 10px;
  flex-direction: row;
  margin-bottom: 32px;
  flex-wrap: wrap;
  column-gap: 8px;
}
li.time-item {
  display: flex;
  column-gap: 12px;
  font-size: 18px;
}
h1.course-title-card {
  font-family: "Roboto";
  font-size: 40px;
}

input.ant-input.learning-page-input {
  width: 100% !important;
  height: 40px !important;
}

.row.course-details {
  padding: 20px;
  column-gap: 30px;
  justify-content: center;
}
.col-12.col-sm-9.col-md-12.col-lg-4.col-xl-4.col-xxl-4.apply-card {
  border: 1px solid rgb(222, 221, 221);
  border-radius: 14px;
  padding: 30px;
  margin-top: 20px;
  height: fit-content;
}
.col-sm-12.col-md-6.col-lg-7.col-xl-7.col-xxl-7.course-detail-container {
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .tab-titles {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    -webkit-overflow-scrolling: auto;
  }

  .tab-titles::-webkit-scrollbar {
    height: 10px;
  }

  .tab-titles::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .tab-titles::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .tab-titles::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
h3.titele-course {
  text-align: justify;
  font-family: 'Poppins';
  color: black;
}
p.course-description {
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
  color: #555;
}
li.prereq-point {
  font-size: 20px;
  font-weight: bold;
}
ul.prereq-flex {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
h2.title-corriculam {
  font-size: 35px;
  font-family: 'Poppins';
  font-weight: 600;
}
h5.mb-0.curriculam-heading {
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 17px;
}
.card-header-clickable.card-header {
  padding: 15px;
}
.topic-curriculam {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
}
li.course-link-vid {
  display: flex;
  align-items: center;
  column-gap: 14px;
}
.card-body {
  padding: 15px;
}

.card-body ul {
  list-style-type: none;
  padding-left: 0;
}

.card-body ul li {
  margin: 5px 0;
}

.card-body ul li a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.card-body ul li a:hover {
  text-decoration: underline;
}

.text-primary {
  margin-right: 15px;
}

.card {
  border: 1px solid #ddd;
}

.card-header {
  cursor: pointer;
  padding: 10px 15px;
}

.card-header h5 {
  margin: 0;
  font-size: 1.2rem;
}

.card-header-clickable {
  cursor: pointer;
}

.card-header span {
  transition: transform 0.3s ease;
}
ul.sub-topic-style {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-left: 10px;
}
span.anticon.anticon-file-done.curriculam-icon {
font-size: 22px;
margin-right: 8px;
}
li.li-course-sub-topic {
padding: 10px;
margin-bottom: 2px;
border-bottom: 1px solid rgb(218, 217, 217);
}