/* Navbar.css */
.new-navbar{
  display: flex;
  align-items: center;
  background-color: #209cff;
  border-radius: 8px;
}
.nav-div{
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  width:75px;
  border-left: 1px solid grey !important;
  border:none;

  height:30px;
  /* box-shadow: 5px 5px white; */
}
  
  .new-navbar a {
    color: black;
    padding: 14px 20px;
    text-decoration: none;
  }
  
  .new-navbar a:hover {
    /* background-color: #575757; */
  }
  
  /* Dropdown container - needed to position the dropdown content */
  .new-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .new-dropbtn {
    /* background-color: #333; */
    color: black;
    padding: 14px 20px;
    border: none;
    cursor: pointer;
    font-weight: normal;
    background-color: #209cff;
    /* background-color: white !important; */
  }
  
  /* Dropdown content (hidden by default) */
  .new-dropdown-content {
    border-top: 2px solid blue;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .new-dropdown-content a {
    color: black;
    /* padding: 12px 16px; */
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .new-dropdown-content a:hover {
    /* background-color: #f1f1f1; */
  }
  
  /* Show the dropdown menu on hover */
  .new-dropdown:hover .new-dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .new-dropdown:hover .dropbtn {
    /* background-color: #575757; */
  }
