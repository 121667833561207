 /* Dark Mode
 .signup-form-container {
    background-color: #2c2c2c; 
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    margin: 10px auto;
    text-align: center;
  }

  
    .form-heading {
      font-size: 2rem;
      font-weight: 600;
      color: #ffffff; 
      margin-bottom: 20px;
    }
    
    .section-title {
      font-size: 1.5rem;
      color: #007bff; 
      margin-bottom: 25px;
      font-weight: 500;
      text-align: left;
    }
    
    .custom-form-group {
      margin-bottom: 25px;
      text-align: left;
    }
    
    .form-label {
      font-weight: 500;
      color: #ffffff; 
      font-size: 1.1rem;
      margin-bottom: 8px;
      display: block;
    }
    
    .input-icon-container {
      position: relative;
      display: flex;
      align-items: center;
    }
    
    .input-icon {
      position: absolute;
      left: 10px;
      font-size: 1.25rem;
      color: #007bff; 
    }
    
    .custom-input {
      border-radius: 8px;
      border: 1px solid #555;
      background-color: #3a3a3a; 
      padding: 12px 12px 12px 40px; 
      font-size: 1.05rem;
      color: #ffffff; 
    }
    select.registrant-select.form-control {
      border-radius: 8px;
      border: 1px solid #555;
      background-color: #3a3a3a;
      padding: 12px 12px 12px 40px;
      font-size: 1.05rem;
      color: #ffffff;
  }
    .custom-input:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
    
    .date-picker {
      border-radius: 8px;
      border: 1px solid #555;
      background-color: #3a3a3a; 
      padding: 12px 12px 12px 40px;
      color: #ffffff; 
    }
    
    .button-container {
      display: flex;
      justify-content: space-between;
    }
    
    .next-btn,
    .back-btn,
    .submit-btn {
      border-radius: 8px;
      padding: 10px 20px;
      font-size: 1rem;
      font-weight: 500;
      border: none;
    }
    
    .next-btn {
      background-color: #007bff;
      color: #ffffff;
    }
    
    .back-btn {
      background-color: #555; 
      color: #ffffff; 
    }
    
    .submit-btn {
      background-color: #28a745; 
      color: #ffffff; 
    }
    
    .next-btn:hover,
    .back-btn:hover,
    .submit-btn:hover {
      opacity: 0.9; 
    }

  .custom-input.date-picker {
    padding: 6px 12px; 
    border-radius: 5px; 
    border: 1px solid #ccc; 
    background-color: transparent; 
  }

  .ant-picker-input > input {
    font-size: 16px; 
    border: none;
    background-color: transparent; 
    outline: none; 
  }

  .ant-picker {
    width: 100%; 
    height: 40px; 
    display: flex;
    align-items: center;
  }

  .ant-picker:hover, .ant-picker-focused {
    border-color: #40a9ff; 
  }

  .ant-picker-input {
    padding: 0;
  }
  form .form-control {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding-left: 38px !important;
  }
  .ant-picker-input > input {
    border: none!important;
    color: white;
  }

  .eye-icon-container {
    position: absolute;
    top: -5%;
    right: 15px;
    font-size: 20px;
    color: #007bff;
  }
  .custom-input::placeholder {
    color: #888;  
    opacity: 1;  
  }

  .custom-input:focus::placeholder {
    color: #bbb;
  }
  .custom-input{
    color: #888;
  }
  .ant-picker-input > input:placeholder-shown {
    border: none !important;
  }
  .ant-picker .ant-picker-suffix {
    color: #3498db; 
    font-size: 1.2em; 
  }

  .custom-input.date-picker {
    border-radius: 8px;
    height: 37px;
    border: 1px solid #ccc;
    background-color: transparent;
  }

  form .form-control {
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 97%;
    padding-left: 38px !important;
  }
  .signup-form-container {
    max-width: 600px;
  }
  .ant-picker.custom-input.date-picker {
    width: 97% !important;
  }
  h3.section-title {
    text-align: center;
    margin-top: 22px;
  }

  */
  .signup-form-container {
    background-color: #f8f9fa; 
    border-radius: 12px;
    max-width: 500px;
    margin: 10px auto;
    text-align: center;
    /* box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1);  */
}

.form-heading {
    font-size: 2rem;
    font-weight: 600;
    color: #333; 
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.5rem;
    color: #007bff; 
    margin-bottom: 25px;
    font-weight: 500;
    text-align: left;
}

.custom-form-group {
    margin-bottom: 10px;
    text-align: left;
}

.form-label {
    font-weight: 500;
    color: #333; 
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
}

.input-icon-container {
    position: relative;
    display: flex;
    align-items: center;
}

.input-icon {
    position: absolute;
    left: 10px;
    font-size: 1.25rem;
    color: #007bff; 
}

.custom-input {
    border-radius: 8px;
    border: 1px solid #ccc; 
    background-color: #fff; 
    padding: 5px 12px 5px 40px; 
    font-size: 1.05rem;
    color: #333; 
}

select.registrant-select.form-control {
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #fff; 
    padding: 12px 12px 12px 40px;
    font-size: 1.05rem;
    color: #333; 
}

.custom-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.date-picker {
    border-radius: 8px;
    border: 1px solid #ccc; 
    background-color: #fff; 
    padding: 12px 12px 12px 40px;
    color: #333; 
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.next-btn,
.back-btn,
.submit-btn {
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    border: none;
}
.ant-picker.custom-input.date {
  margin-right: 15px;
}
.next-btn {
    background-color: #007bff;
    color: #ffffff;
}

.back-btn {
    background-color: #ccc; 
    color: #333; 
}

.submit-btn {
    background-color: #28a745; 
    color: #ffffff; 
}

.next-btn:hover,
.back-btn:hover,
.submit-btn:hover {
    opacity: 0.9; 
}

.custom-input.date-picker {
    padding: 6px 12px; 
    border-radius: 5px; 
    border: 1px solid #ccc; 
    background-color: transparent; 
}

.ant-picker-input > input {
    font-size: 16px; 
    border: none;
    background-color: transparent; 
    outline: none; 
    color: #333; 
}

.ant-picker {
    width: 100%; 
    height: 40px; 
    display: flex;
    align-items: center;
}

.ant-picker:hover, .ant-picker-focused {
    border-color: #40a9ff; 
}

.ant-picker-input {
    padding: 0;
}

form .form-control {
    border-radius: 10px;
    border: 1px solid #ccc;
    
}
input.custom-input.form-control {
  padding-left: 12px !important;
}
.eye-icon-container {
    position: absolute;
  top: -1px;
    right: 20px;
    font-size: 20px;
    color: #007bff;
}

.custom-input::placeholder {
    color: #888;  
    opacity: 1;  
}

.custom-input:focus::placeholder {
    color: #bbb;
}

.custom-input {
    color: #333; 
}

.ant-picker-input > input:placeholder-shown {
    border: none !important;
}

.ant-picker .ant-picker-suffix {
    color: #3498db; 
    font-size: 1.2em; 
}

.custom-input.date-picker {
    border-radius: 8px;
    height: 37px;
    border: 1px solid #ccc; 
    background-color: transparent;
}

form .form-control {
    border-radius: 10px;
    border: 1px solid #ccc; 
    width: 90%;
    padding-left: 12px !important;
    height: 40px;
}
select.registrant-select.form-control {
  width: 94%;
}
span.anticon.anticon-eye-invisible {
  position: absolute;
  right: 12px;
  top: 10px;
}
.eye-icon-container {
  position: absolute;
  top: 0px;
  right: 40px;
  font-size: 20px;
  color: #007bff;
}
.signup-form-container {
    max-width: 600px;
}
.ant-picker.custom-input-date {
  border-radius: 8px;
  width: 90%;
}

.ant-picker.custom-input.date-picker {
    width: 97% !important;
}

h3.section-title {
    text-align: center;
    margin-top: 22px;
    color: #333; 
}
select.registrant-select.form-control {
  height: 40px;
}
select#roleSelect {
  height: 40px;
}

.signup-form-container {
  max-width: 520px;
  padding: 48px;
  border: 1px solid rgb(196, 194, 194);
  margin-top: 20px;
}
input.custom-inpufield.form-control {
  width: 96%;
}
span.anticon.anticon-eye-invisible {
  position: absolute;
  right: -10px;
  top: 10px;
}