.Filter{
   text-align: center;
   padding-top: 2%;
   padding-bottom: 22%;
}
.Filters{
    padding-top: 3%;
}

.Filter1{
   border: 1px solid red;
   width:80%;
   height: auto;
   border-right: 5%;
   margin-left: 9% ;   
}

.Add-List2{
    text-align: center;
    padding: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}  
.row {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
    display: flex !important;
    /* justify-content: center; */
}   
@media only screen and (max-width: 1024px) {
    .Add-List1{
        width:100%;
        height: 100%;
    }
}

