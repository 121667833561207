.checklist {
    list-style: none; /* Removes default bullets */
    padding: 0;       /* Removes default padding */
  }
  
  .checklist li {
    display: flex;               /* Aligns checkmark and text horizontally */
    align-items: center;         /* Centers the items vertically */
    margin-bottom: 8px;          /* Adds some space between list items */
  }
  
  .checkmark {
    margin-right: 8px;           /* Adds space between checkmark and text */
    font-size: 16px;             /* Adjusts the size of the checkmark */
  }