.timer {
    display: flex;
    // justify-content: right;
    float: right;
    background-color: #2e2b2e;
    color: white;
    margin: 0px 20px;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 16px;
}

.quiz-container {
    margin-top: 20px;
    max-width: 50%;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin-left: 80px;
    padding: 20px 20px 0px 20px;
}

.quiz-container .active-question-no {
    font-size: 32px;
    font-weight: 500;
    color: #2e2b2e;
    margin-bottom: 15px; /* Add margin bottom */
}

.quiz-container .total-question {
    font-size: 16px;
    font-weight: 500;
    color: #e0dee3;
}

.quiz-container h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 70px 0; /* Add margin top and bottom */
}

.quiz-container ul {
    // margin: 10px;
    // display: flex;
    margin-top: 20px;
    margin-left: -40px;
}

.quiz-container ul li {
    // display: flex;
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
}

.quiz-container ul .selected-answer {
    // background: #4d484d;
    border: 1px solid #4b484b;
}

.quiz-container button {
    // background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
    background: #2e2b2e;
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}

.quiz-container button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.result h3 {
    font-size: 24px;
    letter-spacing: 1.4px;
    text-align: center;
}

.result p {
    font-size: 16px;
    font-weight: 500;
}

.result p span {
    color: #800080;
    font-size: 22px;
}

// .red-text {
//     color: red;
//   }