// .postContainer{
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
.postheading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.postForm{
    width: -webkit-fill-available;
    height: 100%;
}
.posttitle{
    font-size: 22px;
    font-weight: 500;

}
.inputBox{
    width: 80% !important;
    border-radius: 5px;
    border: 1px solid #120bdb38;
    height: 37px !important;
    outline: none;
  
}
.selectInputBox{
    width: 80% !important;
    border-radius: 7px !important;
    height: 39px !important;
  
}
.profile-InputBox{
    width: 80% !important;
    border-radius: 5px;
    border: 1px solid #ced4da;
    outline: none;
  
}
.postSubmitbutton{
    margin-left: 40%;
    padding: 5px 25px;
    margin-top: 3%;
    margin-bottom: 5%;
    background-color: #3A4F7A;
    box-shadow: 5px 10px 8px  #82AAE3;
    color: white;
    border: 0;
    font-size: 15px;
    font-weight: 600;
    border-radius: 7px;
    transition: .3s;
}
.postSubmitbutton:hover{
    background-color: #009EFF;

}.descriptionInput{
    width: 80%;
    height:70% ;

}
// @media screen and (max-width:987px)