.blog-section {
    padding: 50px 20px;
    text-align: center;
  }
  
  .blog-heading {
    font-size: 32px;
    font-weight: bold;
    color: #0a0a23;
  }
  
  .blog-subheading {
    font-size: 18px;
    color: #6c757d;
    margin-bottom: 40px;
  }
  
  .blog-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .blog-card:hover {
    transform: translateY(-10px);
  }
  
  .blog-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .blog-category {
    background-color: #e3f2fd;
    color: #007bff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .blog-title {
    font-size: 20px;
    color: #0a0a23;
    margin-bottom: 10px;
  }
  
  .blog-author, .blog-date {
    color: #6c757d;
    font-size: 14px;
  }
  
  .load-more-btn {
    margin-top: 20px;
  }
  .title{
    font-size: 38px;
    font-weight: bolder;
  }