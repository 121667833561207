.word-animation {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url('../Images/banner2.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    position: relative;
}


.word-animation::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent);
    animation: pulse 6s infinite;
    z-index: 1;
}

@keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
}


.welcome-title {
    font-size: 3rem;
    font-weight: 800;
    color: #fff;
    text-shadow: 3px 3px 20px rgba(0, 0, 0, 0.9), 0px 0px 15px rgba(255, 255, 255, 0.5);
    margin-bottom: 20px;
    z-index: 2;
    animation: fadeInDown 1.5s ease-out;
}
h1.welcome-title.title {
    font-size: 4rem;
}

@keyframes fadeInDown {
    from { opacity: 0; transform: translateY(-50px); }
    to { opacity: 1; transform: translateY(0); }
}


.tagline {
    font-size: 2.2rem;
    font-weight: 400;
    color: #ffdd57;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
    margin-bottom: 15px;
    z-index: 2;
    animation: fadeInUp 2s ease-out;
}

@keyframes fadeInUp {
    from { opacity: 0; transform: translateY(50px); }
    to { opacity: 1; transform: translateY(0); }
}


.typing {
    font-size: 2.2rem;
    font-weight: 600;
    color: #fff;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
    z-index: 2;
}

.typed-cursor {
    font-size: 2.2rem;
    color: #ffdd57;
    animation: blink 0.75s step-end infinite;
}

@keyframes blink {
    50% { opacity: 0; }
}


.description {
    font-size: 1.3rem;
    color: #e0e0e0;
    text-align: center;
    margin-top: 30px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    max-width: 700px;
    z-index: 2;
    animation: fadeInUp 2.5s ease-out;
}


@media (max-width: 768px) {
    .welcome-title {
        font-size: 3rem;
    }
    .tagline, .typing {
        font-size: 1.8rem;
    }
    .description {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .welcome-title {
        font-size: 2.2rem;
    }
    .tagline, .typing {
        font-size: 1.4rem;
    }
    .description {
        font-size: 1rem;
    }
}
