.View_Profile{
    background-color:#eaeef3;
    // height: 200vh;
}
.View_Profile_imgDetails{
    border-color: 3px solid black;
    margin: 30px 20px;
    box-shadow: 0px 5px 0px 0px #ed9191;
}
.Links_cintainer{
    display: flex;
    justify-content: space-between;
}


.Img_Name_Heading{
    font-weight: bold;
    margin-top: 20px;

}
.View_Profile_ImageSide{
    height:250px;   
}
.View_Profile_Container{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.View_Profile_Container_right{
margin-left: 20%; 
}
.ViewProfile_Button{
    width: 70px;
    background-color: #ff0000;
    border-radius: 5px;
    border: none;
    color: #eaeef3;
    height: 40px;

}
.ViewProfile_Button:hover{
    box-shadow: 2px 2px 5px 2px rgba(110, 108, 108, 0.642);
    
}
.ViewProfile_Contents{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
// .ViewProfile_Button:active{
//     background-color: rgb(19, 163, 24);
// }
// .Notes_Container{
//     height: ;
// }
.Notes_content_contanier{
    margin: 0px 20px;
}
.ViewProfile_cards{
    box-shadow: 0px 5px 0px 0px #ed9191;;

}
.notes_heading{
    margin-top: 20px;
    margin-left: 20px;
    font-size: 25px;
    font-weight: bold;
    color: gray;
}
.NotesDropdown{
    width: 40%;
}
.ViewPage_Fields{
    display: flex;
    justify-content: center;
}
.Notes_Submit_button{
    display: flex;
    justify-content: center;
}
.Notes_submitbtn{
    background-color: #ff0000 !important;
    color: white !important;
    width: 100px;
    font-weight: bolder;
    height: auto;
    border-color: #ff0000;


}
.View_Profile_notesBottom{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 20px;
    font-weight: 600;
}
.ViewProfile_PersonalDetails_heading{
    margin-left: 20px;
    color: grey;
}
.DividerLine_viewProfile{
 display: flex;
 justify-content: center;
}