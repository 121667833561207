.userstab-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tabs {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-link {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .tab-link:hover {
    background-color: #007BFF;
    color: white;
  }
  
  .tab-content {
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  @media (max-width: 768px) {
    .tabs {
      flex-direction: column;
    }
  
    .tab-link {
      width: 100%;
      text-align: center;
    }
  }
  