.term-condition{
    color:rgb(36, 40, 41);
    font-size: 45px;
    font-weight: bold;
    margin-top: 22%;
}
.term-img{
    width: 100%;
    margin-top: 20%;
}
.term-content{
        margin-top: 2%;
        font-family: sans-serif !important;
        line-height: 30px;
        text-align: justify;
        text-transform: lowercase;
}