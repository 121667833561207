/* .right-cont.col-md-6 {
    background-color: #2c2c2c; 
}
.right-cont.col-md-6 {
    width: 40%;
}

select#roleSelect {
    border-radius: 8px;
    border: 1px solid #555;
    background-color: #3a3a3a;
    padding: 12px 12px 12px 40px;
    font-size: 1.05rem;
    color: #ffffff;
} */

.right-cont.col-md-4 {
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1); 
    height: fit-content;
}
.left-content {
    width: auto !important;
    margin-right: 44px;
    position: absolute;
    top: 10px;
}
.left-column {
 position: relative;
 width: 55%;
  }
.left-content {
    padding: 40px;
    background-color: rgb(240, 248, 255);
}
ul.benefits-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}