/* CustomButton.css */
.button-54 {
    /* font-family: "Open Sans", sans-serif; */
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    /* text-transform: uppercase; */
    background-color: rgb(54, 85, 210); /* Default background color */
    color: white; /* Default text color */
    cursor: pointer;
    border: 3px solid  darkblue ;
    padding: 0.25em 0.5em;
    /* box-shadow: 1px 1px 0px 0px darkblue, 2px 2px 0px 0px darkblue, 
                3px 3px 0px 0px darkblue, 4px 4px 0px 0px darkblue, 
                5px 5px 0px 0px darkblue;  */
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border-radius: 8px;
  }
  
  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }
  
  @media (min-width: 768px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }
  

  .trainer-btn {
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #090909;
    width: auto;
    /* padding: 0.7em 1.7em; */
    cursor: pointer;
    font-size: 18px;
    border-radius: 0.5em;
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  }
  
  .trainer-btn:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
  }
  
  .trainer-btn:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .trainer-btn:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: rgb(54, 85, 210);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .trainer-btn:hover {
    color: #ffffff;
    border: 1px solid rgb(54, 85, 210);;
  }
  
  .trainer-btn:hover:before {
    top: -35%;
    background-color:  rgb(54, 85, 210);;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .trainer-btn:hover:after {
    top: -45%;
    background-color: rgb(54, 85, 210);;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
/* 
  .trainer-btnone:hover
  {border: 3px solid blue;
  } */