// .home-top-carousal-container {
//     position: relative;
//   }
  
//   .home-top-carousal-slide {
//     position: relative;
//   }
  
//   .home-top-carousal-image {
//     filter: blur(0px); 
//   }
  
//   .home-top-carousal-text-container {
  
//   }
  
//   .home-top-carousal-fade-in {
//     animation: homeTopCarousalFadeInAnimation 1.5s ease-in;
//   }
  
//   @keyframes homeTopCarousalFadeInAnimation {
//     from {
//       opacity: 0;
//     }
//     to {
//       opacity: 1;
//     }
//   }
  
//   .slick-dots {
//     bottom: 20px !important;
//   }
  
//   .slick-prev, .slick-next {
//     z-index: 2;
//     color: white;
//   }
  
//   .slick-prev:before, .slick-next:before {
//     color: white;
//     font-size: 30px;
//   }
  
.home-top-carousal-container {
    position: relative;
    padding: 0;
  }
  
  .home-top-carousal-slide {
    position: relative;
  }
  p.slider-subtext {
    text-align: justify;
    word-spacing:4px;
    font-weight: 500;
}
  .home-top-carousal-image {
    padding-top: 100px;
    height: 80vh;
    object-fit: cover;
  }
  
  .home-top-carousal-text-container {
    position: absolute;
    top: 35%;
    left: 10%;
    background-color:#ffffff !important;
    right: 0;
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    padding: 30px; 
    height: 300px;
    width: 500px;
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .slider-dot {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
  }
  
  .slider-dots-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .slider-dots {
    margin: 0px;
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 0% !important;
    padding: 0;
    margin: 0;
    top: 94%;
    left: 50%;
    list-style: none;
    text-align: center;
}
ul.slider-dots {
  display: flex;
}