.carousel-zindex {
  z-index: 1;
}

.category-card {
  width: 140px;
  text-align: center;
  height: 150px;
  margin-bottom: 20px;
}

.category-card-img {
  height: 80px;
  object-fit: cover;
}

.category-card-body {
  padding: 10px;
}
.category-card-title {
  font-size: 14px;
}

.hamburger-btn-container {
  align-self: flex-start;
  margin-top: 20px;
}

.offcanvas-custom {
  z-index: 1050;
}

.offcanvas-custom.offcanvas.offcanvas-end.show {
  width: 80%;
}

.carousel-item img {
  height: 280px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}
span.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: black;
  border-radius: 20px;
}
.carousel {
  height: 280px;
  border-radius: 20px;
  width: 90%;
}
.category-card-primary {
  height: 300px;
  width: 200px;
}
.carousal-column.col-md-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-image-container-primary {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: -30px;
}
.category-image-container-primary:hover {
  transform: scale(1.05); /* Zoom in effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Optional: Add shadow */
  cursor: pointer; /* Show pointer cursor on hover */
}
@media (max-width: 767px) {
  .category-card {
    width: 120px;
  }
}
.flex-category {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.input-filoter-category-container {
  position: relative;
}
svg.search-icon {
  position: absolute;
  top: 22px;
  right: 10px;
}
input.ps-4.category-filter-input.form-control {
  height: 50px;
  width: 350px !important;
}
.category-image-container {
  height: 100px;
  width: 100px;
  display: flex;
  column-gap: 40px;
  flex-direction: row;
}
.category-image-container-small {
  height: 100px;
  width: 100px;
  display: flex;
  column-gap: 40px;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(177, 174, 174);
  border-radius: 10px;
}
img.category-image {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.category-filter-container-cont.row {
  display: flex;
  column-gap: 40px;
  padding: 0 36px;
  align-items: center;
}
.top-filter-container.row {
  display: flex;
  justify-content: space-between;
}
.hamburger-container {
  height: 80px;
  width: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9c9797;
  border-radius: 6px;
}

input.ps-4.category-filter-input1.form-control {
  height: 48px;
  font-family: poppins;
  border: 1.6px solid #585656 !important;
}
input.ps-4.category-filter-input1.form-control:focus {
  border-color: #0088ff;
  outline: none;
  font-family: poppins;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.input-filter-category-container {
  position: relative;
}
svg.location-icon1 {
  position: absolute;
  top: 12px;
  left: 6px;
  color: #9c9797;
  font-size: 24px;
}
svg.search-icon1 {
  position: absolute;
  top: 5px;
  right: 8px;
  color: white;
  font-size: 40px;
  padding: 10px;
  border-radius: 6px;
  background-color: orangered;
}
input.ps-4.category-filter-input1.form-control {
  padding-left: 36px !important;
}
.d-flex.justify-content-between.top-flex.row {
  padding: 0 28px;
}
.offcanvas-header {
  display: flex;
  column-gap: 64px;
}
.category-title-home {
  text-align: center;
}

.changing-text-container {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 35px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 1.5em;
}

.dynamic-text {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  animation: fade-up-down 2s ease-in-out infinite;
}

@keyframes fade-up-down {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  45% {
    opacity: 1;
    transform: translateY(0);
  }
  55% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
button.button-filter.btn.btn-primary {
  position: fixed;
  transform: rotate(90deg);
  left: -94px;
  z-index: 30;
  background-color: orangered;
  top: 30%;
}
button.button-filter-2.btn.btn-primary {
  position: fixed;
  transform: rotate(90deg);
  left: -94px;
  z-index: 30;
  background-color: darkblue;
  top: 60%;
}

.category-icon {
  font-size: 50px;
}
.hamburger-container {
  font-size: 50px;
}
.mb-3.small-category-flex.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
}

.flex-category {
  font-size: 20px;
  padding: 10px;
}
.flex-category:hover {
  background-color: #f5eeee;
  padding: 10px;
  border-radius: 4px;
}
