.pricing-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 20px;
  }
  
  .pricing-card {
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .pricing-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .card-gradient-1 .ant-card-head{
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    color: white;
    font-size:x-large;
    font-weight: bold;
  }
  
  .card-gradient-2 .ant-card-head{
    background: linear-gradient(135deg, #4facfe, #00f2fe);
    color: white;
    font-size:x-large;
    font-weight: bold;
  }
  
  .card-gradient-3 .ant-card-head {
    background: linear-gradient(135deg, #a18cd1, #fbc2eb);
    color: white;
    font-size:x-large;
    font-weight: bold;
  }
  
  .card-gradient-4 .ant-card-head {
    background: linear-gradient(135deg, #ff9a9e, #fad0c4);
    color: white;
    font-size:x-large;
    font-weight: bold;
  }

  .know-more-btn {
    border: none;
    color:white;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 15px;
  }
  .card-gradient-1 .know-more-btn {
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
  }
  
  .card-gradient-2 .know-more-btn {
    background: linear-gradient(135deg, #4facfe, #00f2fe);
  }
  
  .card-gradient-3 .know-more-btn {
    background: linear-gradient(135deg, #a18cd1, #fbc2eb);
  }
  
  .card-gradient-4 .know-more-btn {
    background: linear-gradient(135deg, #ff9a9e, #fad0c4);
  }
/* 
  .pricing-card .ant-card-head {
    background: linear-gradient(135deg, #9b59b6, #4a90e2);
    color: white;
    font-weight: bold;
    text-align: center;
  } */
  
  .pricing-details {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .price {
    font-size: 1.8rem;
    font-weight: bold;
    color: #27ae60;
  }
  
  .old-price {
    text-decoration: line-through;
    color: #ccc;
    font-size: 0.9rem;
    margin-left: 10px;
  }
  
  .save-amount {
    color: #e74c3c;
    font-weight: 500;
  }
  
  .package-list {
    padding: 0;
    list-style: none;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .package-list li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    color: #555;
    font-size: 0.95rem;
    transition: all 0.3s ease-in-out;
  }
  
  .package-list li:hover {
    transform: translateX(10px);
    color: #4a90e2;
  }
  
  .list-icon {
    color: #27ae60;
    margin-right: 10px;
    font-size: 1rem;
    transition: color 0.3s ease-in-out;
  }
  
  .package-list li:hover .list-icon {
    color: #4a90e2;
  }
  