.image {
  height: 350px;
  width: 100% !important;
  z-index: -1;
  display: flex;
  justify-content: center;
}
.fading {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  position: absolute;
  color: black;
  top: 0;
  bottom: 0;
  left: 10%;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: flex-start;
}
.Int-popular-head {
  display: flex;
  justify-content: center;
  padding: 30px;
}
.Int-col {
  width: 100%;
}
.Inttitle {
  text-align: center;
  margin-top: 5%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px 20px;
  border: 1px solid #e6e2e2;
  cursor: pointer;
  transition-duration: 0.4s;
  font-size: small;
  font-weight: 600;
  color: rgb(85, 81, 81);
}
.Inttitle:hover {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
}

// .slick-dots li.slick-active button:before {
//   opacity: 0.75;
//   color: rgba(0, 0, 0, 0);

// }
// .slick-dots li button:before {
//   display: none !important;
// }

.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 0% !important;
  margin-bottom: 0;
  margin-left: 0% !important;
  padding-left: 0;
  list-style: none;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 0% !important;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.button {
  background-color: #ffd8d8;
  border: none;
  color: #ff0000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
  white-space: nowrap;
}

.button:hover {
  background-color: #f1f1f1;
}

.logo {
  height: 80px;
  // align-items: center;
}
.star {
  height: 15px;
  margin-right: 15px;
  margin-left: 15px;
}
.staralign {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.cardrow {
  display: flex;
  flex-flow: wrap;
  min-width: 0;
  justify-content: center;
}

//NEWS//

.News-container {
  margin-top: 4em;
}

.last-news {
  position: relative;
  width: 100%;
  height: 50px;
  bottom: 10px;
  .news-title {
    background-color: #ff0000;
    color: #fff;
    display: block;
    height: 50px;
    width: auto;
    font-size: 1rem;
    position: absolute;
    margin-top: 0px;
    margin-left: 20px;
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 3;
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 0px;
      height: 0px;
      top: 0;
      left: -12px;
      border-left: 12px solid transparent;
      border-right: 0px solid transparent;
      border-bottom: 50px solid #ff0000;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 0px;
      height: 0px;
      right: -12px;
      top: 0;
      border-right: 12px solid transparent;
      border-left: 0px solid transparent;
      border-top: 50px solid #ff0000;
    }
  }
  .news-box-colour {
    position: absolute;
    height: 50px;
    width: 100%;
    background-color: gray;
  }
  .news-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    &:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 50px;
      background-color: gray;
      z-index: 2;
    }
  }
  .carousel-last-news {
    margin-left: 140px;
    padding: 7px 0;
    font-size: 0.9rem;
    .carousel-item {
      p {
        margin-bottom: 0;
        margin-top: 8px;
        color: #fff;
      }
    }
  }
  .animated {
    -webkit-animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 0.2s;
    -moz-animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
  }
  .delay-animated {
    -webkit-animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 0.4s;
    -moz-animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .scroll-animated {
    -webkit-animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 3s;
    -moz-animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .slidein {
    -webkit-animation-name: slidein;
    -moz-animation-name: slidein;
    -o-animation-name: slidein;
    animation-name: slidein;
  }
  @-webkit-keyframes slidein {
    from {
      margin-left: 800px;
    }
    to {
      margin-top: 0px;
    }
  }
  @-moz-keyframes slidein {
    from {
      margin-left: 800px;
    }
    to {
      margin-top: 0px;
    }
  }
  .slideup {
    -webkit-animation-name: slideup;
    -moz-animation-name: slideup;
    -o-animation-name: slideup;
    animation-name: slideup;
  }
  @-webkit-keyframes slideup {
    from {
      margin-top: 30px;
    }
    to {
      margin-top: 0;
    }
  }
  @-moz-keyframes slideup {
    from {
      margin-top: 30px;
    }
    to {
      margin-top: 0;
    }
  }
}

// TRENDING CARDS

.Trending_card_container {
  padding: 3%;
}
.posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    display: flex;
    flex-direction: column;
    flex-basis: 27%;
    background-color: #fff;
    margin-bottom: 25px;
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    &--main {
      flex-basis: 66%;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0)
        );
      }
      .posts__image {
        display: flex;
        flex-grow: 1;
      }
      .posts__information {
        position: absolute;
        bottom: 35px;
        left: 50px;
        padding: 0 25px 0 0;
        z-index: 1;
      }
      .posts__title a {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.75px;
        color: #fff;
      }
    }
  }

  &__image > img {
    display: block;
    width: 100%;
  }

  &__information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 25px;
    flex-grow: 1;
  }

  &__date {
    font-size: 13px;
    line-height: 30px;
    letter-spacing: 0.325px;
    color: #818181;
    text-transform: uppercase;
    font-weight: 600;
    margin: 7px 0;
  }

  &__title {
    margin-top: 7px;
    margin-bottom: auto;
    a {
      font-size: 22px;
      line-height: 30px;
      color: #000;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.55px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__author {
    margin-top: 55px;
    img {
      border-radius: 50px;
      width: 60px;
    }
  }
}

@media screen and (max-width: 992px) {
  .posts__information {
    padding: 10px 15px 15px 15px;
  }
  .posts__date {
    font-size: 12px;
  }
  .posts__title a {
    font-size: 16px;
    line-height: 24px;
  }
  .posts__item--main .posts__information .post-title a {
    font-size: 20px;
    line-height: 26px;
  }
}

@media screen and (max-width: 768px) {
  .posts__item {
    flex-basis: 49%;
  }

  .posts__item--main {
    flex-basis: 100%;
    justify-content: center;
    .posts__image {
      display: block;
    }
    .posts__information {
      width: 100%;
      bottom: auto;
      left: auto;
      align-items: center;
      text-align: center;
      padding: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .posts__item {
    flex-basis: 100%;
  }
}

// CARDHOVER

.normal,
.hover {
  flex: 1;
  padding: 0 55px;
  margin-top: 3%;
}
.demo-title {
  color: #666;
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.module {
  min-width: 250px;
  height: 376px;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s linear 0s;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 8px 1px rgb(100, 100, 100);
}
.module:hover {
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
}
.thumbnail {
  position: relative;
  overflow: hidden;
  background: black;
}
.thumbnail img {
  width: 120%;
  transition: all 0.3s;
}
.module:hover .thumbnail img {
  transform: scale(1.1);
  opacity: 0.6;
}

.thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #e74c3c;
  padding-top: 10px;
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
  height: 55px;
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
}
.date div:first-child {
  font-size: 18px;
  line-height: 1.2;
}
.Trending-content {
  position: absolute;
  width: 100%;
  height: 150px;
  bottom: 0;
  background: #fff;
  padding: 20px;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}
.module:hover .Trending-content {
  height: 278px;
}

.Trending-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  color: #fff;
  text-transform: uppercase;
  background: #e74c3c;
  padding: 10px 15px;
  font-weight: bold;
}
.Trending-title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 24px;
  font-weight: 700;
}
.sub-title {
  margin: 0;
  // padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}
.description {
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
  height: 0;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  overflow: hidden;
}
.module:hover .description {
  height: 100px;
}
