.checkbox {
    display: flex;
    // flex-direction: ;
    color: #2b2b2c
}

.model_heading {
    display: flex;
    // justify-content: space-between;

}

.Model_verify {
    color: rgb(19, 10, 10);
    font-size: 20px;
}

.table_button {
    width: 100%;
    height: 90%;
}