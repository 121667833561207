.jobscard {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s; /* Include box-shadow transition */
  cursor: pointer;
  max-width: 95%;
  margin: 10px auto;

  &:hover {
    transform: translateY(-5px); /* Lift the card slightly on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
  }

  h5 {
    margin-bottom: 8px;
    font-size: 1.1rem;
  }

  h6 {
    margin-bottom: 8px;
    font-size: 1rem;
  }

  .mt-1,
  .mt-0 {
    margin-top: 4px;
    font-size: 0.9rem;
  }

  .save-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.2rem;
  }
}

.DescriptionBoxFilter {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* Define the fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Define the fadeInDown animation */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply styles to the Card.Header */
.card-header {
  background-color: white;
  color: red;
  animation: fadeInDown 0.5s;
}
