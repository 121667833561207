.site-main{
    color:rgb(225, 172, 14);
    font-weight: bold;
    font-size: 2em;
}
.site-heading{
    font-weight: bold;
}
.site-content{
   border: none;
}