.instructor-image {
    display: flex;
    column-gap: 30px;
}
img.instructor-img {
    max-width: 100%;
    height: 200px;
}
h2.instructor-name {
    text-align: start;
}
h2.instructor-name {
    font-family: 'Roboto';
}
.bio {
    font-family: poppins;
    font-size: 15px;
    font-weight: 500;
}