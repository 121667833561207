.row {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.caros_img {
  width: 100%;
  height: 68vh;
}

.product {
  position: relative;
  width: 68%;
}

.product_content {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  backface-visibility: hidden;
  cursor: pointer;
}

.product_tit {
  transition: 0.5s;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.product_content:hover {
  transform: scale(1);
}

.product:hover .product_content {
  color: gray;
  opacity: 0.6;
}
.product:hover .product_tit {
  opacity: 1;
}

.product_txt {
  color: black;
  font-size: 1.5rem;
  padding: 16px 32px;
}

.flex_product {
  margin-top: 3%;
  /* margin-bottom: 3%; */
  display: flex;
  justify-content: space-evenly;
}

.discount {
  margin-top: 10px;
  border-radius: 15px;
  cursor: pointer;
}

.offer {
  margin-top: 6%;
}

.icon {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-around; */
  cursor: pointer;
  transition: 0.7s;
  width: 300px;
  height: 400px;
}

/*.icon {
 
  margin: 100px auto; 
  position: relative;
  overflow: hidden;
} */

.icon_img {
  padding-left: 20%;
  /* width: 100%; */
  /* margin-left: 60px; */
  width: 78%;
  height: 50%;
}

.icon:hover img {
  transform: scale(1.1);
}

.icon {
  transition: 0.5s;
}

.text {
  text-align: center;
}

.heading {
  margin-top: 50px;
  /* margin-bottom: 20px; */
  letter-spacing: 1px;
  color: black;
  text-align: center;
}

.icon {
  padding-left: 10%;
  width: 32%;
}

* {
  margin: 0;
  padding: 0;
}

.review {
  width: 80%;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  transition: 0.6s;
}

.review:hover {
  box-shadow: rgba(80, 78, 78, 0.342) -10px 10px,
    rgba(90, 88, 85, 0.19) -20px 20px;
}

.review .content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-weight: 300;
}

.button_ho {
  font-weight: 90%;
  font-size: 18px;
  width: 100%;
  height: 37px;
  text-align: center;
  border: none;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.button_ho:hover {
  background-color: red;
  transform: scale(1);
  box-shadow: gray -5px 5px;
}

.button_ho {
  margin-bottom: 42px;
  width: 123px;
  margin-left: 22%;
  background-color: red;
  color: black;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product {
    width: 90%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .icon {
    width: revert;
    padding-right: 0px;
    padding-left: 50px;
    padding-top: 4%;
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .review {
    width: 100%;
    margin-right: 12%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .button_ho {
    margin-left: 8%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .button_ho {
    margin-left: 20%;
  }
}

@media (min-width: 425px) and (max-width: 538px) {
  .button_ho {
    margin-left: 30%;
  }
}

@media (min-width: 2152px) and (max-width: 2560px) {
  .button_ho {
    margin-left: 35%;
  }
}

.flexicon{
margin-top: 4%;
}
