@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Vollkorn:ital,wght@0,400..900;1,400..900&family=Wittgenstein:ital,wght@0,400..900;1,400..900&display=swap');




:root {
  --pr-color: rgb(8, 56, 113);
  --pr-color-light: rgb(24, 80, 148);
  font-family: "Nunito Sans", sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-fam) !important;
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

/* code {
  font-family: "Jost", sans-serif;
} */

/* INPUT FIELDS */

.input-field {
  width: 90%;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 7px;
  outline: none;
  height: 40px;
}
input,
textarea,
select {
  /* width: 90% !important; */
  padding: 5px !important;
  font-size: 1rem !important;
  border: 1px solid #ced4da !important;
  border-radius: 7px !important;
  outline: none !important;
}
label {
  /* font-weight: 600 !important; */
}
.input-error {
  color: red !important;
}

.ant-tabs-tab-active:focus {
  color: red !important;
  text-transform: uppercase !important;
}

label {
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: 500;
  text-transform: capitalize;
}

.nav-list {
  font-size: 16px !important;
  /* font-family: "Merriweather"; */
  font-weight: 500;
  line-height: 1.5;
  white-space: nowrap;
}
.button1 {
  background-color: #ff0000;
  box-shadow: 0px 0px 2px 1px rgb(54, 45, 45);
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
  width: 120px;
  height: 40px;
  text-transform: uppercase;
  transition: 0.5s;
}
.button2 {
  background-color: gray !important;
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
  width: 120px;
  box-shadow: 0px 0px 2px 1px rgb(31, 27, 27);
  height: 40px;
  text-transform: uppercase;
  transition: 0.5s;
}
.button2:hover {
  background-color: gray !important;
  color: rgb(255, 255, 255) !important;
  box-shadow: 1px 1px 3px 1px rgb(63, 63, 63) inset;
}
.button1:hover {
  background-color: #ff0000 !important;
  color: #ffffff !important;
  box-shadow: 1px 1px 3px 1px #a70000 inset;
}

.button3 {
  background-color: #ff0000;
  box-shadow: 0px 0px 2px 1px rgb(54, 45, 45);
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
  width: 160px;
  height: 40px;
  text-transform: uppercase;
  transition: 0.5s;
}
.button3:hover {
  background-color: #ff0000 !important;
  color: #ffffff !important;
  box-shadow: 1px 1px 3px 1px #a70000 inset;
}
