.outer-container {
  justify-content: space-evenly;
}

.course_landingpage-left--section {
  .inner-container {
    background-color: rgba(248, 248, 248, 0.404);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 50px;
  }

  .lang-btn--wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .languages-landingpage {
    align-self: center;
    margin-bottom: 0px;
  }

  .tick-icons {
    font-size: 25px;
    margin-right: 10px;
  }
  .landingpage-second--section {
    padding-left: 30px;
  }

  .batchtable_date_day,
  .batchtable_time_week {
    display: inline-block;
  }
  .batchtable_date {
    font-size: 18px;
    font-weight: 800;
    color: #192f60;
    min-width: 93px;
    max-width: 93px;
    display: inline-block;
  }

  .batchtable_week {
    padding: 4px 4px 3px 7px;
    background-color: rgba(255, 171, 1, 0.2);
    font-size: 11px;
    font-weight: 500;
    line-height: 1.3;
    color: #a36d00;
    width: 61px;
    display: inline-block;
    margin-left: 9px;
    margin-right: 20px;
  }

  .batchtable_dayweek {
    font-size: 16px;
    font-weight: 600;
    line-height: 0.94;
    color: #192f60;
    margin-right: 35px;
    min-width: 172px;
    max-width: 172px;
    display: inline-block;
  }

  .batchtable_time {
    font-size: 13px;
    line-height: 0.94;
    color: #192f60;
  }

  .allBatch--wrapper {
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    // width: 75%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }

  .batches--title {
    margin-top: 30px;
    margin-left: 30px;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .inner-container--subtitle {
    font-size: 1.4rem;
  }

  .bestseller {
    background-color: #eceb98;
    color: #3d3c0a;
    padding: 4px 4px 3px 7px;
  }

  .createdby--landingpage {
    font-size: 15px;
    font-weight: 500;
  }

  .button-example5 {
    position: relative;
    padding: 1em 2em 1em 2em !important;
    background: #00ef48b9;
    color: #fff;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    cursor: pointer;
    border-radius: 5px;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 1em;
      top: 50%;
      margin-top: -0.7em;
      width: 1.4em;
      height: 1.4em;
      transform: rotate(-45deg);
      transition: transform 0.5s ease, margin 0.5s ease;
      border: solid #fff;
      border-width: 0 0 0.2em;
    }
    &:before {
      border-width: 0 0.2em 0 0;
    }

    &:hover {
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(225deg);
        margin-top: -0.8em;
      }
    }
  }
}
