.learning-outcomes-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.learning-outcome-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 12px;
    color: #333;
    font-weight: 500;
}
h2.h2-content {
    font-family: "Roboto", serif;
    color: #6f6b80;
}
li.learning-outcome-item {
    font-family: 'Poppins';
}