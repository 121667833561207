.login-container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url("https://images.pexels.com/photos/267885/pexels-photo-267885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login-page {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.login-image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.image-container img {
  max-width: 100%;
  height: auto;
}
.login-form-content{
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.login-form-section {
  display: flex;
  justify-content: center;
  
}
.login-title-div {
  width: 100%;
  margin: 0;
  padding: 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-card {
  width: 100%;
  padding: 20px;
  max-width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid white;
  /* background-color: rgb(209, 233, 246, 1) !important; */
  background-color: transparent !important;

  border-radius: 9px !important;
}

.login-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.signup-link-container {
  text-align: center;
  margin-bottom: 1rem;
}

.signup-link {
  color: #007bff;
  font-weight: bold;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  /* padding: 0.75rem; */
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  margin-bottom: 4px;
}

.password-toggle {
  position: absolute;
  padding-top: 45px;
  right: 84px;
  cursor: pointer;
}

.forgot-password-link {
  color: #007bff;
}

.login-button {
  width: 100%;
  background-color: #007bff;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}
.login-button :hover{
  background-color: aliceblue !important;
}
.google-login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #000;
  border: 1px solid #ddd;
  padding: 0.75rem;
  cursor: pointer;
  width: 100%;
  border-radius: 0.25rem;
}

.google-icon {
  width: 20px;
  margin-right: 10px;
}

.error-text {
  color: red;
  font-size: 0.9rem;
  margin-top: 80px;
}
.login-field {
  margin-right: 0;
}
.form-group.login-field {
  margin-right: 0;
}

.forget_password {
  margin-top: 4% !important;
  width: 44%;
  height: 80vh;
}
.forget_input {
  width: 60%;
}
.forget_label {
  margin-right: auto;
  font-weight: bold;
  font-size: 20px;
}
.forget_button {
  width: 60%;
  height: 35px;
}
.forget_link {
  text-decoration: none;
}
.forget_main {
  overflow-y: hidden;
}
.otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 40%;
  align-items: center;
}
.inputStyle {
  width: 60% !important;
  height: 35px;
  border-radius: 7px;
}
.resend-otp {
  cursor: pointer;
  font-size: 15px;
  margin-left: 15%;
  margin-top: 10px;
  color: #0d6efd;
}
.forgot-error-text {
  margin-top: 2px;
  color: #d65e5ef7;
  margin-left: 8%;
  position: fixed;
  font-size: 14px;
}

/* 
.login-container {
  padding: 2rem;
}

.form-section {
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  width: 100%;
}
.categories-section.d-flex.flex-column.justify-content-center.align-items-center.col-md-6.col-12 {
  padding: 18px;
}
.card-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 1rem;
}
.form-form {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.login-container {
  padding: 2rem;
  padding-top: 105px;
}
.a-container {
  background-color: white;
  display: flex;
  z-index: 10 !important;
  align-content: center;
  justify-content: center;
}

.fade-in {
  opacity: 1 !important;
}

h2 {
  color: #333;
}

.toggle-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.categories-section {
  padding: 1rem;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.course-card {
  padding: 1rem;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
}

.announcement-section {
  padding: 1rem;
}

.announcement-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 300px; 
  overflow: hidden;
  position: relative;
}

.announcement-list {
  animation: slideUp 10s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.announcement-card {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.announcement-card:last-child {
  border-bottom: none;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.form-container .btn {
  background-color: #04aa6d;
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  width: 40%;
  margin-bottom: 10px;
  margin-top: 19px;
  opacity: 0.8;
}
.form-container {
  max-width: 361px;
  padding: 21px;
  background-color: white;
  border-radius: 10px;
}

.announcement-container {
position: relative;
padding-top: 20px;
}

.announcement-title {
position: relative;
background-color: #f8f9fa;
z-index: 2;
padding: 10px;
text-align: center;
border-radius: 5px;
top: -20px;
}

.announcement-list {
margin-top: 20px;
z-index: 1;
}

.announcement-card {
margin-bottom: 15px;
border-radius: 5px;
transition: box-shadow 0.3s;
}

.announcement-card:hover {
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.card-container {
display: flex;
flex-direction: column;
gap: 20px;
}

.course-card {

padding: 20px;
}

.course-card h3 {
margin-top: 10px;
}

.categories-section {
padding: 20px;
} */

input.form-control.custom {
  padding-left: 10px !important;
}
