
.Shop{
    font-weight: bold;
    font-size: 17px;
}
.dropdown_contents{
    max-height: 90px;
    width: 70%;
    display: flex;
    justify-content: space-around;
}
.stationary-login{
    margin-left: 27%;     
}
.stationary-signup{
    margin-left: 3%;
}

.thing_img{
    width: 100;
}
.Addimg{
    margin-bottom:5%;
    width: 50%;
    margin-left: 25%;
    box-shadow: 1px 1px 3px 3px red;
}
.stationary-card{
    margin-top: 5%;
}
.cart1{
    display: flex;
    justify-content: center;
    padding-bottom: 4%;
} 
.card_ho{
    cursor: pointer;
    transition: 0.5s;
}
.card_ho:hover{
    transform: scale(1.00);
    box-shadow: 2px 2px 2px 2px rgb(129, 131, 131);
}
.Card_in{
    /* text-align: left; */
    /* border-radius: 3px; */
    background-color: rgb(238, 235, 235);
    text-align: center;
    /* margin-left: 20px; */
    margin-top: 95%;
    color: rgb(240, 6, 6);
    font-size: 20px;
    font-weight: bold;
}
.Card_in:hover{
    /* box-shadow: 1px 1px 3px 3px rgb(243, 156, 156); */
    cursor: pointer;
}

.color{
    /* background-color: rgb(109, 213, 231); */
    padding: 5px;
    text-align: left;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 20px;
}
.bg_color{
    background-color: rgb(248, 48, 48);
}
.start{
    margin-top: 15%;
    color: rgb(13, 212, 185);
    text-align: left;
    font-style: italic;

}
.offer_color{
    background-color: rgb(241, 212, 114);
    margin-top: 3%;
    margin-left: 25%;
    margin-right: 25%;
}
.All_image{
    border-radius: 50%;
    margin-top: 10%;
}
.row {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
}