.pop-btn{
    background-color: var(--pr-color);
    border: none;
    color:white;
    font-size: 16px;
    margin-left:-5px;
}
.pop-btn:hover{
    background-color: white;
    color: black;
}