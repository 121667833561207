
.project-heading p {
  display: flex;
  justify-content: center;
  font-size: 40px !important;
  font-weight: 400;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.project-heading {
  display: flex; 
  justify-content: center;
  align-items:last baseline;
 height: 25vh;

}

.card-items img {
  border-radius: 10px;
}

.project-col {
    display: flex;
    justify-content: space-evenly;
}
.card-items {
  width: 330px;
}

.project-row {
  width: 95%;
  margin-left: 2%;
}

.first-row {
  display: flex;
//   justifycontent: space-evenly;
//   flexdirection: row;
}
.card-footer {
  border-top: none;
  background-color: white;
  padding-left: 25vh;
}
button.project-view-btn.btn.btn-primary {
  padding: 10px 60px;
}