.about-main-img {

    background-image: url(https://preview.colorlib.com/theme/appy/images/header-bg.jpg.webp);
    background-size: cover;
    background-position:bottom;
    height: 50%;
    background-attachment: fixed;
    
   

    // width:100%;
    // height:70vh;
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // display: grid;
    // place-items: center;
}


.image-about-head
{
   

    width: 90vh;
    height: 109vh;
  
}

.home-about-color
{
    color: white;
}
.home-about-color:hover
{
    color: skyblue;
}

.aboutus-cnt
{
    color: white;
    display: flex;
    justify-content: start;
    align-items: "end";
    font-family:sans-serif,josefin sans;
    font-size: 7vh;
    font-weight: 600;
}
.about-button:hover
{
background-color: rgb(26 110 203);
}
.about-main-img-text {
    font-size: 100px;
    font-family: 'IntroBold';
    color:white;
    background: linear-gradient(
    to right,
    rgb(26 110 203),
    rgb(146 84 202 / 47%),
    rgb(14 37 219 / 63%),
  
  );
    height: 50vh;
    width: 100%;
    align-content: center;
    line-height: 76px;
 
}

.what-we-row {
   padding-top: 6rem;
    background-color:rgb(227, 230, 234);
    color: black;
    height: 25rem;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.what-we-do_txt b {
    font-family: 'IntroBold';
    display: flex; 
    justify-content: center;
    text-align: center;
    font-size: 70px;
}

.what-we-cnt {
    display: flex;
    font-family: 'OpenSans-Regular';
    font-size: 21px;
    padding:  5px 80px 5px 50px ;
}

.grp-dis-txt {
    display:flex;
    justify-content: center;
    align-items: center;

}

.grp-dis-txt b {
    font-size: 32px;
}
.grp-dis-txt p {
    font-size: 18px;
}
.story-row {
    display:flex;
    justify-content: center;
    align-items: center;
}

.story-cnt {
    display:flex;
    justify-content: center;
    align-items: center;
}

.story-img img {
    margin-left: 5vh;
}

.about-header {
    color: rgb(15, 16, 16);
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40%;
    margin: auto;
}

.about-img1 {
    height: 75vh;
    box-shadow: 0 12px 16px 0 rgb(0 0 0 / 24%), 0 17px 50px 0 rgb(0 0 0 / 19%);
    border-radius: 5px;
    width: 90%;
}

.about-main {
    color: rgb(4, 4, 4);
    font-family: Arial, Helvetica, sans-serif;
    font-size: x-large;
    font-weight: bold;
    margin-top: 3%;
    text-align: justify;
}

.about-content {
    margin-top: 2%;
    font-family: sans-serif !important;
    line-height: 30px;
    text-align: justify;
}

.about-btn {
    color: aliceblue;
    background-color: rgb(24 150 188);
    border: none;
    height: 6vh;
    border-radius: 2%;
    font-size: larger;
}

.about-start-btn {
    color: aliceblue;
    background-color: rgb(83, 92, 95);
    border: none;
    // height: 10vh;
    // width: 85%;
    border-radius: 5%;
    font-size: 25px;
}

.project-btn {
    color: rgb(14, 15, 16);
    background-color: white;
    border: none;
    // height: 10vh;
    // width: 85%;
    font-size: 20px;
}

.project-btn:hover {
    background-color: rgb(243, 239, 240);
    color: rgb(102, 75, 156);
}

.about-img2 {
    height: 55vh;
    box-shadow: 0 12px 16px 0 rgb(0 0 0 / 24%), 0 17px 50px 0 rgb(0 0 0 / 19%);
    border-radius: 5px;
    width: 100%;
}

.side-content {
    font-weight: bold;
}

.about-img3 {
    height: 75vh;
    width: 100%;
}