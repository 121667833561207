.home-image {
    height: 500px;
    width: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
  }
  .home-head{
    display: flex;
    justify-content: center;
    color:rgb(191, 94, 29);
    font-style: oblique;
    font-weight: bolder;
  }
  .home-btnn{
    background-color: black;
    color: white;
  }
  .btn-more{
    background-color: bisque;
    color: rgb(182, 100, 24);
    border-radius: 10px 15px 10px 15px;
  }
  .home-emp-foot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(90deg, #8d8a8a 41%, #84817e 100%);
    height: 25vh;
    margin-left: -4%;
}
.home-emp-center{
    color:rgb(19, 18, 18);
}
.home-emp-img{
  width: 100%;
  display: flex;
  justify-content: center;
}
//   .scrolling{
//     border: 1px solid rgb(65, 65, 65);
//     border-radius: 5px;
//     height: 50vh;
//     overflow: hidden;
//   }
//   .home-scroll{
//     height: 100%;
//     text-align: center;
//     display: flex;
//     justify-content: center;
    
    
//     /* animation properties */
//     -moz-transform: translateX(-100%);
//     -webkit-transform: translateX(-100%);
//     transform: translateX(-100%);
    
//     -moz-animation: my-animation 5s linear infinite;
//     -webkit-animation: my-animation 5s linear infinite;
//     animation: my-animation 5s linear infinite;
//   }
  
//   /* for Firefox */
//   @-moz-keyframes my-animation {
//     from { -moz-transform: translateX(-100%); }
//     to { -moz-transform: translateX(100%); }
//   }
  
//   /* for Chrome */
//   @-webkit-keyframes my-animation {
//     from { -webkit-transform: translateX(-100%); }
//     to { -webkit-transform: translateX(100%); }
//   }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    to {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }