.Student-Myprofile .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
  align-items: last baseline;
  height: 10vh;
}
.Student-response .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.ant-menu-submenu-title {
  padding-left: 0 !important;
}

/**Personal Information**/
.Stud-personal-head {
  display: flex;
  justify-content: center;
}
.Stud-personal-col {
  width: 100%;
}
.Stud-personal-form {
  display: flex;
  flex-direction: column;
}
.Stud-personal-error {
  color: red;
}
.Stud-personal-radio {
  display: flex;
  justify-content: space-evenly;
}
.Stud-personal-input {
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem !important;
  border: 1px solid #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
}
.Stud-personal-btn {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3%;
}
.Stud-personal-btns {
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}
.Stud-personal-submit {
  background-color: #1a64c4ea;
  padding: 5px 10px;
}
.Stud-personal-cancel {
  background-color: rgb(110, 107, 107);
  padding: 5px 10px;
}
.Stud-personal-submit:hover {
  background-color: #31531e;
}
.Stud-personal-cancel:hover {
  background-color: rgb(145, 16, 16);
}

/**Qualification Information**/
.Stud-qual-head {
  display: flex;
  justify-content: center;
}
// .gender-checkbox , .marital-checkbox{
//   font-weight: 600;
// }
.Stud-qual-col {
  width: 100%;
}
.Stud-qual-form {
  display: flex;
  flex-direction: column;
}
.Stud-qual-error {
  color: red;
}
.Stud-qual-radio {
  display: flex;
  justify-content: space-evenly;
}
.Stud-qual-input {
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem !important;
  border: 1px solid #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
}
.Stud-qual-btn {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3%;
}
.Stud-qual-btns {
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}
.Stud-qual-submit {
  background-color: #1a64c4ea;
  padding: 5px 10px;
}
.Stud-qual-cancel {
  background-color: rgb(110, 107, 107);
  padding: 5px 10px;
}
.Stud-qual-submit:hover {
  background-color: #31531e;
}
.Stud-qual-cancel:hover {
  background-color: rgb(145, 16, 16);
}
.Stud-qual-table {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
}
.Stud-qual-table::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

/**Semester Score**/
.addbutton {
  border-radius: 8px;
  border: none;
  background-color: #ff0000;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.603);
  color: white;
  padding: 5px;
  // margin-top:-50px
}
.Stud-sem-head {
  display: flex;
  justify-content: center;
}
.Stud-sem-col {
  width: 100%;
}
.Stud-sem-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  outline: none;
}
.Stud-sem-addbtn {
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #06468f;
  box-shadow: 0px 1px 1px 1px #414040;
}
.Stud-sem-btn {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3%;
}
.Stud-sem-btns {
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}
.Stud-sem-submit {
  background-color: #1a64c4ea;
  padding: 5px 10px;
}
.Stud-sem-cancel {
  background-color: rgb(110, 107, 107);
  padding: 5px 10px;
}
.Stud-sem-submit:hover {
  background-color: #31531e;
}
.Stud-sem-cancel:hover {
  background-color: rgb(145, 16, 16);
}

/**AREA INTEREST **/
.Stud-aresint-head {
  display: flex;
  justify-content: center;
}
.Stud-aresint-col {
  width: 100%;
}
.Stud-aresint-input {
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem !important;
  border: 1px solid #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
  width: 80% !important;
  border-radius: 8px;
}
.Stud-aresint-btn {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3%;
}
.Stud-aresint-btns {
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}
.Stud-aresint-submit {
  background-color: #1a64c4ea;
  padding: 5px 10px;
}
.Stud-aresint-cancel {
  background-color: rgb(110, 107, 107);
  padding: 5px 10px;
}
.Stud-aresint-submit:hover {
  background-color: #31531e;
}
.Stud-aresint-cancel:hover {
  background-color: rgb(145, 16, 16);
}

/**BLOG**/
.Stud-blog-head {
  display: flex;
  justify-content: center;
}
.Stud-blog-col {
  width: 100%;
}
.Stud-blog-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  outline: none;
  height: 40px;
}
.Stud-blog-addbtn {
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #06468f;
  box-shadow: 0px 1px 1px 1px #414040;
}
.Stud-blog-btn {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3%;
}
.Stud-blog-btns {
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}
.Stud-blog-submit {
  background-color: #1a64c4ea;
  padding: 5px 10px;
}
.Stud-blog-cancel {
  background-color: rgb(110, 107, 107);
  padding: 5px 10px;
}
.Stud-blog-submit:hover {
  background-color: #31531e;
}
.Stud-blog-cancel:hover {
  background-color: rgb(145, 16, 16);
}
.stud-profil-btn1{
  text-decoration: none;
  background-color:rgb(73, 118, 201) ;
  border: none;
  border-radius: 10px;
  color: azure;
  width: 150px;
  font-size: 18px;
  transition: all 0.3s ease;
}
.stud-profil-btn1:hover{
  transform: translateY(2px); 
  box-shadow: 0px 3px 15px rgb(73, 118, 201); 
 
}

.button1{
  background-color:rgb(73, 118, 201) !important;
}
.button1:hover{
  background-color:rgb(73, 118, 201) !important;
  box-shadow: rgb(73, 118, 201) !important ;
}
.kyc-input{
  width: 50% !important;
  border-radius: 5px;
  border: 1px solid gray;
  height: 37px;
}

.Stud-exam-col {
  width: 100%;
}
.Stud-exam-input {
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  outline: none;
}
.exam_btn {
  border: none;
  background: none;
  color: #e90a0a;
}
.quiz-title {
  color: #06468f;
  font-size: 50px;
}
.mb-3 {
  // background-color: aqua;
  margin-top: 30px;
}
// .btn {
//   // border-radius: 20px !important;
//   // background-color:#1890ff !important;
//   background-color: var(--pr-color);
//   color: white !important;
//   border-radius: 10px !important;
//   border: none !important;
//   width: 100px;
//   box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.603);
//   margin-top: 200px;
//   margin-left: 240px;
// }
// .btn:hover {
//   background-color: rgb(62, 121, 231) !important;
// }
.question-section {
  // background: rgb(134, 116, 116);
  height: 150px;
  width: 1000px;
  // position:absolute;
}
.question-text {
  font-size: 30px;
  font-style: italic;
  // position: relative;
  margin-top: -48px;
  margin-left: 70px;
  // margin-left: 29px;
}
.question-count {
  color: black;
  font-size: 30px;
  font-style: italic;
  margin-top: 50px;
  // position: fixed;
}
.score-section {
  color: black;
  font-size: 30px;
  font-style: italic;
  position: absolute;
  margin-top: 70px;
}
.score_section_button {
  font-size: 20px;
  margin-left: 15px;
}
.answer-section {
  // background: rgb(124, 107, 107);
  color: black;
  font-size: 18px;
  width: 1000px;
  padding-right: 50px;
  display: flex;
  justify-content: space-evenly;
}
.answer-button {
  background: rgb(187, 205, 207);
  width: 140px;
  height: 50px;
  margin-top: 150px;

  align-items: center;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.answer-button:hover {
  background: rgb(73, 118, 201) !important;
}
.Stud_quiz .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.Stud_quiz .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 33px !important;
  margin-top: 5px;
}
.Stud_quiz .ant-select-selection-placeholder {
  color: #808086;
  font-size: 16px;
}

.vendor_inputbox {
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-right: 15px;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 100px) and (max-width: 400px) {
  .vendor_inputbox {
    display: flex;
    width: 100%;
    margin-top: 5px;
    margin-right: 0px;
    align-items: center;
    justify-content: space-between;
  }
}

// SEMINAR
.studseminar {
  border-radius: 5px;
  background-color: rgb(52, 178, 209);
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: white;
}
.studseminardetail {
  display: flex;
}
.studsemimode {
  margin-left: 10%;
}
.seminardate {
  margin-left: 12%;
}
.seminartime {
  margin-left: 12%;
}
.seminarconract {
  margin-left: 5%;
}
.seminarnumber {
  margin-left: 4%;
}
.studsemifees {
  margin-left: 12%;
}
.studsemiaudience {
  margin-left: 9%;
}
.studsemiabout {
  margin-left: 11%;
}
.studsemireg {
  margin-left: 5%;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 38px !important;
  padding: 0 11px;
  border-radius: 5px !important;
  color: rgba(0, 0, 0, 0.945);
  font-size: 16px;
}


.internship-heading {
  font-weight: bold;
  text-transform: uppercase;
}

.internship-details-card {
  padding: 10px 20px 10px 10px; 
  margin-bottom: 0.1px;
  background-color: #f9f9f9;
  border: 5px solid #dddddd62;
  border-radius: 1px;
  // text-align: justify;
}

.internship-details-card h6 {
  padding: 10px 20px 10px 10px; 
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
  // text-align: center;
}

.internship-details-card p {
  font-size: 0.875rem;
  margin: 1;
  padding-left: 5px; 
  color: #555;
}

.login-button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.text-primary {
  color: #007bff !important;
}

.container {
  padding: 1rem 0;
}


.seminarpage {
  .studseminardetail {
    margin-bottom: 20px;
    h6 {
      font-weight: bold;
      margin-bottom: 5px;
      margin-right: 3px;
    }
    p {
      margin: 0;
    }
  }
}

.studsemireg {
  color: blue;
}
.custom-container {
  max-width: 800px;
  margin: 0 auto; 
  border: 2px solid black;
  padding: 20px; 
}






/* student kyc deva changes  */
.container.aos-init.aos-animate {
  padding: 50px;
  border-radius: 20px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
  border: none !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  padding-top: 5px !important;

}


input.Professional_UpdateProfile_Input {
  height: 43px;
}

.card-footer {
  padding: 22px 0 !important;
  display: flex !important;
  justify-content: center !important;

}

.area-of-intrest-container {
  display: flex;
  justify-content: center;
}
.blog-container {
  display: flex;
  justify-content: center;
}