.counsellor-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  .counsellor-card-container-landing {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 50px;
  }  
  .counsellor-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 280px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .counsellor-card-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 16px;
  }
  
  .counsellor-card-body {
    text-align: center;
  }
  
  .counsellor-card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .counsellor-card-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 8px;
  }
  
  .counsellor-chat-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .counsellor-chat-button:hover {
    background-color: #0056b3;
  }
  .view-more-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 40px;
  }
  
  .view-more-button:hover {
    background-color: #0056b3;
  }
  
  